import { Injectable, inject } from '@angular/core';
import {
	GenericTable,
	ModulePages2,
	ModulesPermission2,
	PageAction2,
	RoleHeader,
	RoleList2,
	UserRoleForms,
	RoleUser,
	ModulePages,
	OverrideModulePages,
	GroupedMenuAction,
} from './index';
import { BehaviorSubject, catchError, filter, finalize, map, tap } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { BreadcrumbService } from '@app/shared/navigation/breadcrumb/breadcrumb.service';
import { formatQueryParams } from '@app/shared/utilities/helper';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@app/shared/interfaces/user.interface';
import { SpinnerService } from '@app/core/services/spinner.service';
import { UserService } from '@app/core/services/user.service';
import { NotificationService } from '@app/core/services/notification.service';

@Injectable({
	providedIn: 'root',
})
export class RolesAndPermissionService {
	//inject
	//private _dummy = inject(RolesAndPermissionDummyService);
	private _http = inject(HttpClient);
	private _breadcrumbService = inject(BreadcrumbService);
	private _router = inject(Router);
	private _activated = inject(ActivatedRoute);
	public spinner = inject(SpinnerService);

	//props
	private _roles$ = new BehaviorSubject<RoleList2[]>([]);
	roles$ = this._roles$.asObservable();
	private _roleId$ = new BehaviorSubject<number | null>(null);

	private _modules2$ = new BehaviorSubject<ModulesPermission2[]>([]);
	get modules2$() {
		return this._modules2$.asObservable();
	}
	private _selectedModule$ = new BehaviorSubject<ModulesPermission2 | null>(
		null
	);

	private _pages$ = new BehaviorSubject<ModulePages2[] | null>(null);
	public _editedPages$ = new BehaviorSubject<ModulePages2[] | null>(null);
	private _loadedMenu$ = new BehaviorSubject<ModulesPermission2[] | null>(null);
	private _roleHeader$ = new BehaviorSubject<RoleHeader | null>(null);

	private _state$ = new BehaviorSubject<RolesAndPermissionState>({
		mode: 'add',
		parent: 'default',
		text: 'Create New',
		backLink: '/roles-and-permissions',
		companyId: 0,
		url: 'ParentChildCompany/GetCurrentChildren/',
		isEditPageLoading: false,
		currentRoleId: 0,
		menuIndex: 0,
		isCreateHeaderSaved: false,
		companyBackLink: '/companies',
	});
	private _userForms$ = new BehaviorSubject<GenericTable | null>(null);
	private _roleForms$ = new BehaviorSubject<GenericTable | null>(null);

	private _moduleCompanyId$ = new BehaviorSubject<number | null>(null);
	private _moduleCompany$ = new BehaviorSubject<any>(null);

	private _queryParams: UserRoleFormsQueryParams = {
		page: 1,
		pageSize: 10,
		column: 'name',
		order: 'asc',
		userId: 2,
		roleId: 2,
	};
	formType: 'user' | 'role' = 'user';
	buttonLabel$ = new BehaviorSubject<string>('Change Access');

	private _auditTrailUpdate = new BehaviorSubject<any>('');
	auditTrailUpdate = this._auditTrailUpdate.asObservable();
	private _isUserClientAdmin = false;

	private _isRoleOverride$ = new BehaviorSubject(false);
	isRoleOverride$ = this._isRoleOverride$.asObservable();
	setIsOverride = () => {
		this._isRoleOverride$.next(true);
	};
	setIsNotOverride = () => {
		this._isRoleOverride$.next(false);
	};

	private _isCascade$ = new BehaviorSubject(false);
	isCascade$ = this._isCascade$.asObservable();
	setIsCascade = () => {
		this._isCascade$.next(true);
	};
	setIsNotCascade = () => {
		this._isCascade$.next(false);
	};

	constructor(
		private _userService: UserService,
		private _notifier: NotificationService
	) {
		this.initCompanyUrl(0);
	}

	// public method
	init() {
		this.initRolesList();
	}

	initRolesList() {
		const companyId = this.stateCompanyId
			? this.stateCompanyId
			: this.moduleCompanyId
			? this.moduleCompanyId
			: 0;
		this.spinner.start();
		this._API.rolesList(companyId).subscribe((v) => {
			this._roles$.next(v);
		});
	}

	initRoleForms(/* params: UserRoleFormsQueryParams|null=null */) {
		this.spinner.start();
		this._API.getRoleForms(this._queryParams).subscribe((v) => {
			this._roleForms$.next(v);
			this.roleForms$.subscribe((v) => {});
		});
	}

	initUserForms(/* params: UserRoleFormsQueryParams|null=null */) {
		this.spinner.start();
		this._API.getUserForms(this._queryParams).subscribe((v) => {
			this._userForms$.next(v);
		});
	}

	setModule(module: ModulesPermission2) {
		if (this._editedPages$.value && this._editedPages$.value.length > 0)
			this._updateEditedPages();

		if (this._loadedMenu$.value) {
			const loadedMenu = this._loadedMenu$.value!.find(
				(v) => v.id === module.id
			);
			if (loadedMenu != undefined) {
				if (this._editedPages$.value !== null) {
					this._selectedModule$.next(loadedMenu);
					const page = this._editedPages$?.value!.filter((v) => {
						// bug
						if (module.id === v.menuGroupId) return true;
						else return false;
					});
					this._pages$.next(page as ModulePages2[]);
					this.setStateIsEditPageLoading(false);
				} else {
					this._loadedMenu$.next([module]);
					this._selectedModule$.next(module);
					this.setPages();
				}
			} else {
				let loaded = this._loadedMenu$.value;
				loaded.push(module);
				this._loadedMenu$.next(loaded);
				this._selectedModule$.next(module);
				this.setPages();
			}
		} else {
			this._loadedMenu$.next([module]);
			this._selectedModule$.next(module);
			this.setPages();
		}
	}

	setPages() {
		let { userId, companyId, isAdmin } = this._state$.value;
		let roleId = this._roleId$.value!;
		//if(this.modeState === 'add') roleId=0;
		if (this.modeState != 'override') userId = 0;
		if (this._checkAndSetCurrentPage()) return;
		this.spinner.start();
		this._API
			.menuGroup(
				roleId,
				this._selectedModule$.value!.id,
				userId,
				companyId,
				isAdmin
			)
			.pipe(finalize(() => this.setStateIsEditPageLoading(false)))
			.subscribe((v) => {
				this._pages$.next(v);
				this._addPageToEdited(v);
			});
	}

	private _addPageToEdited(modulePages: ModulePages2[]) {
		let edited: any[] = [];
		if (
			this._editedPages$.value &&
			this._editedPages$.value.length > 0 &&
			this._editedPages$?.value !== null
		)
			edited = [...edited, ...this._editedPages$.value];
		edited = [...edited, ...modulePages];
		this._editedPages$.next(edited);
	}

	private _checkAndSetCurrentPage() {
		let isExisting = false;
		const { menuGroupId } = this._selectedModule$.value!;
		const editedPages = this._editedPages$.value;
		if (editedPages && editedPages.length > 0) {
			let pages: any[] = [];
			editedPages.forEach((v) => {
				if (v.menuGroupId == menuGroupId) pages.push(v);
			});
			if (pages.length > 0) {
				isExisting = true;
				this._pages$.next(pages);
			}
		}

		this.setStateIsEditPageLoading(false);
		return isExisting;
	}

	private _updatePageToEdited() {
		// update natin dito yung changes ng page
	}

	updateCurrentPage(page: ModulePages2) {
		let pages = this._pages$.value!.map((v) => {
			if (v.id == page.id) {
				const access = !page.isEnableAccess;
				const action = page.menuActions.map((x) => {
					x.isEnableAccess = access;
					return x;
				});
				return {
					...v,
					...{ isEnableAccess: !page.isEnableAccess, menuActions: action },
				};
			} else return v;
		});
		this._pages$.next(pages);
	}

	updateCurrentPageCheckbox(page: ModulePages2, actionId: number) {
		let pages = this._pages$.value!.map((v) => {
			if (v.id == page.id) {
				let pageAccess = false;
				let actions = v.menuActions.map((x) => {
					if (x.id === actionId && !page.isEnableAccess) pageAccess = true;
					else if (x.id != actionId && x.isEnableAccess) pageAccess = true;

					if (x.id == actionId)
						return { ...x, ...{ isEnableAccess: !x.isEnableAccess } };
					else return x;
				});

				return {
					...v,
					...{ isEnableAccess: pageAccess, menuActions: actions },
				};
			} else {
				return v;
			}
		});
		this._pages$.next(pages);
	}

	updateAllAccess(access: boolean) {
		let pages = this._pages$.value!.map((v) => {
			v.isEnableAccess = access;
			let action = v.menuActions.map((x) => {
				return { ...x, ...{ isEnableAccess: access } };
			});
			return { ...v, ...{ menuActions: action } };
		});
		this._pages$.next(pages);
	}

	initRoleEditPage(
		id: number | null = null,
		companyId: number | null = null,
		isAdmin: boolean | null = null
	) {
		if (id) this.setCurrentRoleId(id);
		this._editedPages$.next(null);
		this.spinner.start();
		if (this._isRoleOverride$.value) {
			let companyId = 0,
				userId = 0;
			if (this.modeState === 'company-override')
				companyId = this.moduleCompanyId!;
			if (this.modeState === 'override') userId = this.stateUserId!;

			this._API
				.getRoleOverride(this._roleId$.value!, companyId, userId)
				.subscribe((v) => {
					this._isCascade$.next(v);
				});
		}
		this._API.getRole(this._roleId$.value!).subscribe((v) => {
			if (this._roleId$.value! != this.stateCurrentRoleId)
				this.setStateMenuIndex(0);
			this.setStateCurrentRoleId(v.id);
			this._roleHeader$.next(v);
			isAdmin = v.isAdmin;
			this.setStateIsAdmin(isAdmin);
			v.companyId ? (companyId = v.companyId) : '';
			this.loadUserMenu(id, companyId, isAdmin);
			this.setBreadcrumb(v.name);
		});
	}

	initRoleViewPage(
		id: number | null = null,
		companyId: number | null = null,
		isAdmin: boolean | null = null
	) {
		this.initRoleEditPage(id, companyId, isAdmin);
	}

	initRoleAddPage(
		id: number | null = null,
		companyId: number | null = null,
		isAdmin: boolean | null = null
	) {
		this._userService.isClientAdmin
			? (this._isUserClientAdmin = true)
			: (this._isUserClientAdmin = false);
		this.setStateIsCreateHeaderSaved(false);
		this._editedPages$.next(null);
		this.setCurrentRoleId(0);
		this.setStateIsAdmin(false);
		//this.loadUserMenu(id, companyId, isAdmin)
		let defaultHeader = this._defaultRoleHeader;
		// if(!this.moduleCompanyId) // remove as per changes, wag mag assign ng companyId kapag walang selected
		//   defaultHeader = {...defaultHeader, ...{companyId: this._userService.companyId}}
		this._roleHeader$.next(defaultHeader);
		this.setStateIsEditPageLoading(false);
	}

	initOverridePage(
		id: number | null = null,
		companyId: number | null = null,
		isAdmin: boolean | null = null
	) {
		this._editedPages$.next(null);
		this.spinner.start();
		if (this._isRoleOverride$.value) {
			let companyId = 0,
				userId = 0;
			if (this.modeState === 'company-override')
				companyId = this.moduleCompanyId!;
			if (this.modeState === 'override') userId = this.stateUserId!;

			this._API
				.getRoleOverride(this._roleId$.value!, companyId, userId)
				.subscribe((v) => {
					this._isCascade$.next(v);
				});
		}
		this._API.getRole(this._roleId$.value!).subscribe((v) => {
			if (this._roleId$.value! != this.stateCurrentRoleId)
				this.setStateMenuIndex(0);
			this.setStateCurrentRoleId(v.id);
			this._roleHeader$.next(v);
			isAdmin = v.isAdmin;
			this.setStateIsAdmin(isAdmin);
			this.spinner.start();
			this._API.getUser(this.stateUserId!).subscribe((v) => {
				this.setCompanyId(v.companyId);
				this.loadUserMenu(id, v.companyId, isAdmin);
			});
			this.setBreadcrumb(v.name);
		});
	}

	mapGroupToOverrideModal() {
		const grouped: { [key: string]: GroupedMenuAction } = {};
		let menus = JSON.parse(JSON.stringify(this._mapOverridePages()));
		menus.forEach((menu: any) => {
			menu.menuActions.unshift({
				defaultRoleAccess: menu.defaultRoleAccess,
				id: menu.menuId,
				isEnableAccess: menu.isEnableAccess,
				name: 'Access',
			});
		});
		console.log(menus);
		menus.forEach((menu: any) => {
			console.log(menu);
			const groupName = menu.menuGroupName;

			if (!grouped[groupName]) {
				grouped[groupName] = {
					menuGroupName: groupName,
					icon: menu.icon,
					// menuName: menu.menuName,
					menuActions: [],
				};
			}

			// Append menu actions with menuName added to each action
			const actionsWithMenuName = menu.menuActions
				.filter(
					(action: any) => action.defaultRoleAccess !== action.isEnableAccess
				) // Filter based on condition
				.map((action: any) => ({
					...action,
					menuName: menu.menuName, // Add the menuName to the action
				}));

			grouped[groupName].menuActions.push(...actionsWithMenuName);
		});
		console.log(Object.values(grouped));
		return Object.values(grouped).filter((obj) => obj.menuActions.length > 0);
	}

	loadUserMenu(
		id: number | null = null,
		companyId: number | null = null,
		isAdmin: boolean | null = null
	) {
		if (!this.stateIsCreateHeaderSaved && this.modeState === 'add') return;
		this.spinner.start();
		this._API
			.userMenu(this._roleId$.value!, companyId, isAdmin)
			.pipe(
				filter((v) => v?.length > 0),
				tap((v) => {
					if (v.length > 0) this.setModule(v[this.menuIndex!]); // this will set default upon loading
				})
			)
			.subscribe((v) => this._modules2$.next(v));
	}

	setRoleHeader(header: Partial<RoleHeader>) {
		let role = { ...this._roleHeader$.value!, ...header };
		this._roleHeader$.next(role);
	}

	saveHeaderOnly() {
		this.setStateIsEditPageLoading(true);
		let companyId = 0;
		let newHeader = this._roleHeader$.value!;
		if (this.moduleCompanyId) {
			companyId = this.moduleCompanyId;
			newHeader = { ...newHeader, ...{ companyId } };
			this._roleHeader$.next(newHeader);
		} else if (this._isUserClientAdmin) {
			companyId = this._userService.companyId;
			newHeader = { ...newHeader, ...{ companyId } };
			this._roleHeader$.next(newHeader);
		}
		this.spinner.start();
		this._API
			.addUpdateRole(this._roleHeader$.value!)
			.pipe(finalize(() => this.setStateIsEditPageLoading(false)))
			.subscribe((v) => {
				// start loading menu
				const resp = v as any;
				const id = resp.id;
				this.setStateIsCreateHeaderSaved(true);
				this.setCurrentRoleId(id);
				if (this._roleId$.value! != this.stateCurrentRoleId)
					this.setStateMenuIndex(0);
				this.setStateCurrentRoleId(id);
				const roleHeader = { ...this._roleHeader$.value!, ...{ id } };
				this._roleHeader$.next(roleHeader);
				this.setStateIsAdmin(roleHeader.isAdmin);
				companyId ? '' : (companyId = resp.companyId);
				this.loadUserMenu(id, companyId, roleHeader.isAdmin);

				//this.initRoleEditPage((v as any).id)
				//this.loadUserMenu(null, null, (v as any).isAdmin)
			});
	}

	saveChanges(isPending: boolean = false) {
		this.setStateIsEditPageLoading(true);

		let header = this._roleHeader$.value;

		if (isPending) {
			header = { ...header, ...{ isFinal: false } } as any;
			this._roleHeader$.next(header);
		}

		if (this.moduleCompanyId) {
			let newHeader = this._roleHeader$.value!;
			this._roleHeader$.next({
				...newHeader,
				...{ companyId: this.moduleCompanyId! },
			});
		}

		let options = this._roleHeader$.value!;
		const companyId =
			this._userService.isClientAdmin || this._userService.isSpAdmin
				? this._userService.companyId
				: 0;

		if (options.companyId === null) {
			options = { ...options, ...{ companyId } };
		}

		if (
			this._userService.isClientAdmin &&
			(options.companyId === 0 || options.isDefault)
		) {
			this._notifier.notifyError(
				'Additional Access Required',
				'Contact administrator to change permission'
			);
			return;
		}
		this.spinner.start();
		if (this.modeState === 'add' || this.modeState === 'edit') {
			this._API
				.addUpdateRole(options)
				.pipe(
					catchError((e) => {
						this.setStateIsEditPageLoading(false);
						return e;
					})
				)
				.subscribe((v) => {
					if (this._state$.value.mode === 'add') {
						const pages = this._pages$.value?.map((x) => {
							x.roleId = (v as any)?.id || (v as any).data.id;
							return x;
						});

						this._roleId$.next((v as any)?.id || (v as any).data.id);
						this._pages$.next(pages!);
					}
					this._updateEditedPages();
					let editedPages = this._mapEditedPages();
					this.spinner.start();

					this._API
						.addUpdateRoleMenuAccess(editedPages)
						.pipe(
							finalize(() => {
								this.setStateIsEditPageLoading(false);
								this._router.navigateByUrl(
									`/roles-and-permissions/manage/${this._roleId$.value!}/users`
								);
							})
						)
						.subscribe((y) => {});

					this._editedPages$.next(null);
					this._loadedMenu$.next(null);
				});
		} else if (this.modeState === 'override') {
			// user override
			this._updateEditedPages();
			let editedPages = this._mapEditedPages();
			editedPages = editedPages.map((val) => {
				const userId = this.stateUserId!;
				val.userId = this.stateUserId!;
				val.menuActions = val.menuActions.map((s) => ({ ...s, userId }));
				return val;
			});
			let newEditedPages: OverrideModulePages = {
				// for the nth time changes -_-
				menuAccessList: editedPages,
				roleId: this._roleId$.value!,
				userId: this.stateUserId,
				isOverride: this._isCascade$.value,
			};
			this._API
				.overrideAddUpdateRoleMenuAccess(newEditedPages)
				.pipe(finalize(() => this.setStateIsEditPageLoading(false)))
				.subscribe((y) => {});
			this._router.navigateByUrl(this.stateOverrideBackLink!);
			this._editedPages$.next(null);
			this._loadedMenu$.next(null);
		} else if (this.modeState === 'company-override') {
			this._updateEditedPages();
			let editedPages = this._mapEditedPages();
			let newEditedPages: OverrideModulePages = {
				// for the nth time changes -_-
				menuAccessList: editedPages,
				roleId: this._roleId$.value!,
				companyId: this.moduleCompanyId!,
				isOverride: this._isCascade$.value,
			};
			this._API
				.overrideAddUpdateCompanyMenuAccessOverride(newEditedPages)
				.pipe(finalize(() => this.setStateIsEditPageLoading(false)))
				.subscribe((y) => {});
			const activated = this._activated;
			const url =
				this._router.routerState.snapshot.url
					.replace('edit', 'manage')
					.replace('add', `manage/${header?.id}`) + '/users';
			this._router.navigateByUrl(url);
			this._editedPages$.next(null);
			this._loadedMenu$.next(null);
		}
	}

	_mapEditedPages() {
		return this._editedPages$.value!.map((val) => {
			return {
				// modify data as per new API requirement
				menuId: val.id,
				roleId: this._roleId$.value!,
				isEnableAccess: val.isEnableAccess,
				menuActions: val.menuActions,
				userId: 0,
			};
		});
	}

	_mapOverridePages() {
		console.log('trigger');
		return this._editedPages$.value!.map((val) => {
			return {
				// modify data as per new API requirement
				menuId: val.id,
				menuName: val.name,
				roleId: this._roleId$.value!,
				isEnableAccess: val.isEnableAccess,
				defaultRoleAccess: val.defaultRoleAccess,
				menuActions: val.menuActions,
				userId: 0,
				menuGroupId: val.menuGroupId,
				icon:
					this._modules2$.value.find((obj) => val.menuGroupId === obj.id) !==
					null
						? this._modules2$.value.find((obj) => val.menuGroupId === obj.id)!
								.icon
						: '',
				menuGroupName:
					this._modules2$.value.find((obj) => val.menuGroupId === obj.id) !==
					null
						? this._modules2$.value.find((obj) => val.menuGroupId === obj.id)!
								.name
						: '',
			};
		});
	}

	// this._modules2$ //list of user menu

	deleteRole(id: number) {
		this.spinner.start();
		this._API
			.deleteRole(id)
			.pipe(finalize(() => this.spinner.stop()))
			.subscribe((v) => {
				this.initRolesList();
				this.reloadAuditTrail();
			});
	}

	// private  method
	_updateEditedPages() {
		let edited = this._editedPages$.value!;
		let current = this._pages$.value;
		edited = edited.map((v) => {
			let found = current?.find(
				(x) => x.menuGroupId === v.menuGroupId && x.id === v.id
			);
			return found ? found : v;
		});
		this._editedPages$.next(edited);
	}

	//update override pages
	updateMenuActionsIsPush(condition: { id: number; isPush: boolean }) {
		let menuData = this._editedPages$.value!;
		menuData.forEach((menuItem) => {
			// Loop through each menuAction in the menuActions array
			menuItem.menuActions.forEach((action) => {
				// Check if the action's id matches the condition's name
				if (action.id === condition.id) {
					// Update the isPush property
					action.isPush = condition.isPush;
				}
			});
		});
		this._editedPages$.next(menuData);
	}

	//APIs
	private _moduleLink = environment.apiBaseUrl + 'RolesAndPermissions-v2/';
	private _usersLink = environment.apiBaseUrl + 'Users/';
	private _apiLink = {
		getRole: this._moduleLink + 'GetRole',
		getRoleOverride: this._moduleLink + 'GetRoleOverride',
		rolesList: this._moduleLink + 'GetCounts',
		deleteRole: this._moduleLink + 'Delete',
		AddUpdateUsers: this._moduleLink + 'AddOrUpdateUsers',
		ExportUsers: this._moduleLink + 'ExportUsers',
		getUserMenu: this._moduleLink + 'GetUserMenu',
		getRoleUsers: this._moduleLink + 'GetUsers',
		setRoleUsers: this._moduleLink + 'AddOrUpdateUsers',
		exportRoleUsers: this._moduleLink + 'exportUsers',
		getMenuGroup: this._moduleLink + 'GetMenuGroupByRole',
		addUpdateRoleMenuAccess: this._moduleLink + 'AddOrUpdateRoleMenuAccess',
		overrideAddUpdateRoleMenuAccess:
			this._moduleLink + 'AddOrUpdateUserMenuAccessOverride',
		addUpdateCompanyMenuAccessOverride:
			this._moduleLink + 'AddOrUpdateCompanyMenuAccessOverride',
		addUpdateRole: this._moduleLink + 'AddOrUpdateRole',
		getRoleForms: this._moduleLink + 'GetRoleForms',
		getUserForms: this._moduleLink + 'GetUserForms',
		addUpdateUserForms: this._moduleLink + 'AddOrUpdateUserForms',
		addUpdateRoleForms: this._moduleLink + 'AddOrUpdateRoleForms',
		getRoleTicketBoards: this._moduleLink + 'GetRoleTicketBoards',
		getUserTicketBoards: this._moduleLink + 'GetUserTicketBoards',
		addUpdateUserTicketBoards: this._moduleLink + 'AddOrUpdateUserTicketBoards',
		addUpdateRoleTicketBoards: this._moduleLink + 'AddOrUpdateRoleTicketBoards',
		getRoleKBTopics: this._moduleLink + 'GetRoleKBTopics',
		getUserKBTopics: this._moduleLink + 'GetUserKBTopics',
		addUpdateUserKBTopics: this._moduleLink + 'AddOrUpdateUserKBTopics',
		addUpdateRoleKBTopics: this._moduleLink + 'AddOrUpdateRoleKBTopics',
		getRoleApplications: this._moduleLink + 'GetRoleApplications',
		getUserApplications: this._moduleLink + 'GetUserApplications',
		addUpdateUserApplications: this._moduleLink + 'AddOrUpdateUserApplications',
		addUpdateRoleApplications: this._moduleLink + 'AddOrUpdateRoleApplications',
		getUser: this._usersLink,
		// override switch
		getApplicationUserAccessOverride:
			this._moduleLink + 'GetApplicationUserAccessOverride',
		setApplicationUserAccessOverride:
			this._moduleLink + 'SetApplicationUserAccessOverride',
		getKBTopicUserAccessOverride:
			this._moduleLink + 'GetKBTopicUserAccessOverride',
		setKBTopicUserAccessOverride:
			this._moduleLink + 'SetKBTopicUserAccessOverride',
		getFormnUserAccessOverride: this._moduleLink + 'GetFormUserAccessOverride',
		setFormnUserAccessOverride: this._moduleLink + 'SetFormUserAccessOverride',
		getBoardUserAccessOverride: this._moduleLink + 'GetBoardUserAccessOverride',
		setBoardUserAccessOverride: this._moduleLink + 'SetBoardUserAccessOverride',
		// company override
		setFormCompanyOverride: this._moduleLink + 'SetFormCompanyAccessOverride',
		setKbTopicCompanyOverride:
			this._moduleLink + 'SetKBTopicCompanyAccessOverride',
		setBoardCompanyOverride: this._moduleLink + 'SetBoardCompanyAccessOverride',
		setApplicationCompanyOverride:
			this._moduleLink + 'SetApplicationCompanyAccessOverride',

		getFormCompanyOverride: this._moduleLink + 'GetFormCompanyAccessOverride',
		getKbTopicCompanyOverride:
			this._moduleLink + 'GetKBTopicCompanyAccessOverride',
		getBoardCompanyOverride: this._moduleLink + 'GetBoardCompanyAccessOverride',
		getApplicationCompanyOverride:
			this._moduleLink + 'GetApplicationCompanyAccessOverride',
	};
	private _API = {
		rolesList: (companyId: number = 0) => {
			let paramsUrl = ``;
			if (companyId) paramsUrl = `?companyId=${companyId}`;
			return this._http
				.get<RoleList2[]>(this._apiLink.rolesList + paramsUrl)
				.pipe(finalize(() => this.spinner.stop(1000)));
		},
		getRole: (roleId: number) => {
			let params = '';
			if (this.modeState === 'add') params = '?isAdmin=false';
			else if (this.modeState === 'edit') params = `?id=${roleId}`;
			else if (this.modeState === 'override')
				params = `?id=${roleId}`; // this will not be used anymore? I think? --jireh
			else '';

			return this._http
				.get<RoleHeader>(this._apiLink.getRole + params)
				.pipe(finalize(() => this.spinner.stop()));
		},
		getRoleOverride: (
			roleId: number,
			companyId: number = 0,
			userId: number = 0
		) => {
			// return if override or cascade
			let params = `/${roleId}?companyId=${companyId}&userId=${userId}`;
			return this._http
				.get<boolean>(this._apiLink.getRoleOverride + params)
				.pipe(finalize(() => this.spinner.stop()));
		},
		deleteRole: (roleId: number) =>
			this._http
				.delete(this._apiLink.deleteRole + `?roleId=${roleId}`)
				.pipe(finalize(() => this.spinner.stop())),
		roleUsers: (roleId: number, companyId: number) =>
			this._http
				.get<RoleUser[]>(
					this._apiLink.getRoleUsers +
						`?roleId=${roleId}&companyId=${companyId}`
				)
				.pipe(finalize(() => this.spinner.stop())),
		setRoleUsers: (
			roleId: number,
			usersAdded: number[],
			usersRemoved: number[],
			isFinal: boolean
		) =>
			this._http
				.post(this._apiLink.setRoleUsers, {
					roleId,
					usersAdded,
					usersRemoved,
					isFinal,
				})
				.pipe(finalize(() => this.spinner.stop())),
		exportRoleUsers: (roleId: number, exportType: number, companyId: number) =>
			this._http
				.get(this._apiLink.exportRoleUsers, {
					params: { roleId, exportType, companyId },
					responseType: 'text',
				})
				.pipe(finalize(() => this.spinner.stop())),
		userMenu: (
			roleId: number,
			companyId: number | null = null,
			isAdmin: boolean | null = null
		) => {
			let queryParams = `?roleId=${roleId}`;

			if (companyId != null) queryParams += `&companyId=${companyId}`;
			if (isAdmin != null) queryParams += `&isAdmin=${isAdmin}`;

			return this._http
				.get<ModulesPermission2[]>(this._apiLink.getUserMenu + queryParams)
				.pipe(finalize(() => this.spinner.stop()));
		},
		menuGroup: (
			roleId: number,
			menuGroupId: number,
			userId: number = 0,
			companyId: number = 0,
			isAdmin: boolean | null = null
		) => {
			let params = `?roleId=${roleId}&menuGroupId=${menuGroupId}`;
			if (userId) params += `&userId=${userId}`;
			if (companyId) params += `&companyId=${companyId}`;
			if (isAdmin != null) params += `&isAdmin=${isAdmin}`;
			return this._http
				.get<ModulePages2[]>(this._apiLink.getMenuGroup + params)
				.pipe(finalize(() => this.spinner.stop()));
		},
		addUpdateRoleMenuAccess: (data: Partial<ModulePages2>[]) => {
			return this._http
				.post(this._apiLink.addUpdateRoleMenuAccess, data)
				.pipe(finalize(() => this.spinner.stop()));
		},
		overrideAddUpdateRoleMenuAccess: (data: Partial<OverrideModulePages>) => {
			return this._http
				.post(this._apiLink.overrideAddUpdateRoleMenuAccess, data)
				.pipe(finalize(() => this.spinner.stop()));
		},
		overrideAddUpdateCompanyMenuAccessOverride: (
			data: Partial<OverrideModulePages>
		) => {
			return this._http
				.post(this._apiLink.addUpdateCompanyMenuAccessOverride, data)
				.pipe(finalize(() => this.spinner.stop()));
		},
		addUpdateRole: (data: RoleHeader) =>
			this._http
				.post(this._apiLink.addUpdateRole, data)
				.pipe(finalize(() => this.spinner.stop())),
		getRoleForms: (queryParams: any) => {
			const query = this._formatQueryParams(queryParams);
			return this._http
				.get<GenericTable>(this._apiLink.getRoleForms + `?${query}`)
				.pipe(finalize(() => this.spinner.stop()));
		},
		getUserForms: (queryParams: any) => {
			const query = this._formatQueryParams(queryParams);
			return this._http
				.get<GenericTable>(this._apiLink.getUserForms + `?${query}`)
				.pipe(finalize(() => this.spinner.stop()));
		},
		addUpdateUserForms: () => {
			return this._http
				.post(this._apiLink.addUpdateUserForms + ``, null)
				.pipe(finalize(() => this.spinner.stop()));
		},
		getUser: (userId: number) => {
			return this._http
				.get<User>(this._apiLink.getUser + userId)
				.pipe(finalize(() => this.spinner.stop()));
		},
	};

	private _formatQueryParams(params: UserRoleFormsQueryParams) {
		let query = `page=${params.page}&pageSize=${params.pageSize}&column=${params.column}&order=${params.order}`;
		if (this.formType === 'role') {
			query = query + `&roleId=${params.roleId}`;
		} else {
			query = query + `&userId=${params.userId}`;
		}

		return query;
	}

	// setters and getters
	setCurrentRoleId(id: number) {
		this._roleId$.next(id);
	}
	setQueryParams(params: Partial<UserRoleFormsQueryParams>) {
		this._queryParams = { ...this._queryParams, ...params };
	}
	get currentRoleId() {
		return this._roleId$.asObservable();
	}

	get activeModuleId$() {
		return this._selectedModule$
			.asObservable()
			.pipe(map((v) => v?.menuGroupId));
	}
	get activeModule() {
		return this._selectedModule$.asObservable();
	}
	get pages$() {
		return this._pages$.asObservable().pipe(
			map((v) => {
				if (this.modeState === 'add') {
					v?.map((x) => {
						let id = 1;
						return x.menuActions.map((y) => {
							if (y.id === 0) y.id = id++; // as per debugging, id are 0 so let's assign id for checkbox
							return y;
						});
					});
				}
				return v;
			})
		);
	}
	get activeModule$() {
		return this._selectedModule$.asObservable();
		//.pipe(map(v=>({name: v?.name, description: v?.description})))
	}
	get roleHeader$() {
		return this._roleHeader$.asObservable();
	}

	get isDisabledPermissionButton() {
		const companyId =
			this._userService.isClientAdmin || this._userService.isSpAdmin
				? this._userService.companyId
				: 0;
		let options = this._roleHeader$.value!;

		if (options?.companyId === null) {
			options = { ...options, ...{ companyId } };
		}

		return (
			this._userService.isClientAdmin &&
			(options?.companyId === 0 || options?.isDefault) &&
			this.modeState !== 'add'
		);
	}

	setState(mode: RnPMode) {
		if (mode === 'add') {
			this._state$.next({
				...this._state$.value,
				...{ mode: 'add', text: 'Create New' },
			});
		} else if (mode === 'edit') {
			this._state$.next({
				...this._state$.value,
				...{ mode: 'edit', text: 'Update' },
			});
		} else if (mode === 'override') {
			this._state$.next({
				...this._state$.value,
				...{ mode: 'override', text: 'Override' },
			});
		} else if (mode === 'view') {
			this._state$.next({
				...this._state$.value,
				...{ mode: 'view', text: 'View' },
			});
		} else if (mode === 'company-override') {
			this._state$.next({
				...this._state$.value,
				...{ mode: 'company-override', text: 'Override' },
			});
		}
	}
	setStateParent(parent: 'default' | 'company') {
		this._state$.next({ ...this._state$.value, ...{ parent } });
	}
	setStateUserId(userId: number) {
		this._state$.next({ ...this._state$.value, ...{ userId } });
	}
	setStateIsAdmin(isAdmin: boolean) {
		this._state$.next({ ...this._state$.value, ...{ isAdmin } });
	}
	setStateIsEditPageLoading(isEditPageLoading: boolean) {
		this._state$.next({ ...this._state$.value, ...{ isEditPageLoading } });
	}
	setStateCurrentRoleId(currentRoleId: number) {
		this._state$.next({ ...this._state$.value, ...{ currentRoleId } });
	}
	setStateMenuIndex(menuIndex: number) {
		this._state$.next({ ...this._state$.value, ...{ menuIndex } });
	}
	setStateIsCreateHeaderSaved(isCreateHeaderSaved: boolean) {
		this._state$.next({ ...this._state$.value, ...{ isCreateHeaderSaved } });
	}
	setOverrideBackLink(overrideBackLink: string) {
		this._state$.next({ ...this._state$.value, ...{ overrideBackLink } });
	}
	setCompanyBackLink(companyBackLink: string) {
		this._state$.next({ ...this._state$.value, ...{ companyBackLink } });
	}
	setOverrideParams(roleId: number, userId: number) {
		this.setState('override');
		this.setStateUserId(userId);
		this.setCurrentRoleId(roleId);
		const btoaId = btoa(this.stateUserId!.toString())
			.replace('=', '')
			.replace('=', '');
		this.setOverrideBackLink(`/contacts/${btoaId}/profile`);
	}

	get _defaultRoleHeader(): RoleHeader {
		return {
			id: 0,
			baseRoleId: 0,
			companyId: 0,
			name: 'New Role',
			description: '',
			isAdmin: false,
			isFinal: false,
			userCount: 0,
			isDefault: 0,
			enableDeleteProtection: false,
			hasOverrideUsers: false,
		};
	}

	modeState$() {
		return this._state$.asObservable().pipe(map((v) => v.mode));
	}
	get modeState() {
		return this._state$.value.mode;
	}
	get modeText$() {
		return this._state$.asObservable().pipe(map((v) => v.text));
	}
	get backLink$() {
		return this._state$.asObservable().pipe(map((v) => v.backLink));
	}
	get backLink() {
		return this._state$.value.backLink;
	}
	get stateCurrentRoleId() {
		return this._state$.value.currentRoleId;
	}
	get stateParent() {
		return this._state$.value.parent;
	}
	get menuIndex() {
		return this._state$.value.menuIndex;
	}
	get stateCompanyId() {
		return this._state$.value.companyId;
	}
	get stateOverrideBackLink() {
		return this._state$.value.overrideBackLink;
	}
	get stateCompanyBackLink() {
		return this._state$.value.companyBackLink;
	}
	get stateIsCreateHeaderSaved() {
		return this._state$.value.isCreateHeaderSaved;
	}
	get url$() {
		return this._state$.asObservable().pipe(map((v) => v.url));
	}
	get userForms$() {
		return this._userForms$.asObservable();
	}
	get roleForms$() {
		return this._roleForms$.asObservable();
	}
	get moduleCompanyId$() {
		return this._moduleCompanyId$.asObservable();
	}
	get moduleCompanyId() {
		return this._moduleCompanyId$.value;
	}
	get moduleCompany$() {
		return this._moduleCompany$.asObservable();
	}
	get moduleCompany() {
		return this._moduleCompany$.value;
	}
	get withCompany() {
		return !(
			this._moduleCompany$.value === null ||
			this._moduleCompanyId$.value === null
		);
	}
	get stateUserId() {
		return this._state$.value.userId;
	}
	get isEditPageLoading$() {
		return this._state$.asObservable().pipe(map((v) => v.isEditPageLoading));
	}

	setModuleCompanyId(companyId: number) {
		this._moduleCompanyId$.next(companyId);
		this.setState('company-override');
	}
	setModuleCompany(company: any) {
		this._moduleCompany$.next(company);
	}
	getRole(roleId: number) {
		return this._API.getRole(roleId);
	}
	getRoleUsers(options: any) {
		return this._http.get<GenericTable>(
			this._apiLink.getRoleUsers + formatQueryParams(options)
		);
	}
	setRoleUsers(
		roleId: number,
		usersAdded: number[],
		usersRemoved: number[],
		isFinal: boolean
	) {
		return this._API.setRoleUsers(roleId, usersAdded, usersRemoved, isFinal);
	}
	setCompanyId(id: number) {
		this._state$.next({ ...this._state$.value, ...{ companyId: id } });
	}
	initCompanyUrl(id: number) {
		this.setCompanyId(id);
		const url = this._state$.value.url + this._state$.value.companyId;
		this._state$.next({ ...this._state$.value, ...{ url: url } });
	}
	exportRoleUsers(roleId: number, type: number, companyId: number) {
		return this._API.exportRoleUsers(roleId, type, companyId);
	}
	setBreadcrumb(name: string) {
		this._breadcrumbService.updateBreadCrumbsText('_rpRoleName', name);
	}

	getRoleData(roleId: number) {
		return this._http.get<RoleHeader>(this._apiLink.getRole + `?id=${roleId}`);
	}
	getRoleForms(options: any) {
		return this._http.get<GenericTable>(
			this._apiLink.getRoleForms + formatQueryParams(options)
		);
	}
	getUserForms(options: any) {
		return this._http.get<GenericTable>(
			this._apiLink.getUserForms + formatQueryParams(options)
		);
	}
	updateRoleForms(roleId: number, formIds: number[]) {
		return this._http.post(this._apiLink.addUpdateRoleForms, {
			roleId,
			formIds,
		});
	}
	updateUserForms(roleId: number, formIds: number[], userId: number) {
		return this._http.post(this._apiLink.addUpdateUserForms, {
			userId,
			roleId,
			formIds,
		});
	}
	getRoleTicketBoards(options: any) {
		return this._http.get<GenericTable>(
			this._apiLink.getRoleTicketBoards + formatQueryParams(options)
		);
	}
	getUserTicketBoards(options: any) {
		return this._http.get<GenericTable>(
			this._apiLink.getUserTicketBoards + formatQueryParams(options)
		);
	}
	updateRoleTicketBoards(roleId: number, ticketBoardIds: number[]) {
		return this._http.post(this._apiLink.addUpdateRoleTicketBoards, {
			roleId,
			ticketBoardIds,
		});
	}
	updateUserTicketBoards(
		roleId: number,
		ticketBoardIds: number[],
		userId: number
	) {
		return this._http.post(this._apiLink.addUpdateUserTicketBoards, {
			userId,
			roleId,
			ticketBoardIds,
		});
	}
	getRoleKBTopics(options: any) {
		return this._http.get<GenericTable>(
			this._apiLink.getRoleKBTopics + formatQueryParams(options)
		);
	}
	getUserKBTopics(options: any) {
		return this._http.get<GenericTable>(
			this._apiLink.getUserKBTopics + formatQueryParams(options)
		);
	}
	updateRoleKBTopics(roleId: number, kbTopicIds: number[]) {
		return this._http.post(this._apiLink.addUpdateRoleKBTopics, {
			roleId,
			kbTopicIds,
		});
	}
	updateUserKBTopics(roleId: number, kbTopicIds: number[], userId: number) {
		return this._http.post(this._apiLink.addUpdateUserKBTopics, {
			userId,
			roleId,
			kbTopicIds,
		});
	}
	getRoleChildCompanies(companyId: number) {
		return this._http.get<any[]>(
			`${environment.apiBaseUrl}ParentChildCompany/GetCurrentChildren/${companyId}?access=roles`
		);
	}
	getRoleApplications(options: any) {
		return this._http.get<GenericTable>(
			this._apiLink.getRoleApplications + formatQueryParams(options)
		);
	}
	getUserApplications(options: any) {
		return this._http.get<GenericTable>(
			this._apiLink.getUserApplications + formatQueryParams(options)
		);
	}
	updateRoleApplications(roleId: number, applicationIDs: number[]) {
		return this._http.post(this._apiLink.addUpdateRoleApplications, {
			roleId,
			applicationIDs,
		});
	}
	updateUserApplications(
		roleId: number,
		applicationIDs: number[],
		userId: number
	) {
		return this._http.post(this._apiLink.addUpdateUserApplications, {
			userId,
			roleId,
			applicationIDs,
		});
	}
	// company override
	getApplicationCompanyAccessOverride(options: any) {
		return this._http.get<GenericTable>(
			this._apiLink.getApplicationCompanyOverride + formatQueryParams(options)
		);
	}
	getKBTopicCompanyAccessOverride(options: any) {
		return this._http.get<GenericTable>(
			this._apiLink.getKbTopicCompanyOverride + formatQueryParams(options)
		);
	}
	getFormnCompanyAccessOverride(options: any) {
		return this._http.get<GenericTable>(
			this._apiLink.getFormCompanyOverride + formatQueryParams(options)
		);
	}
	getBoardCompanyAccessOverride(options: any) {
		return this._http.get<GenericTable>(
			this._apiLink.getApplicationCompanyOverride + formatQueryParams(options)
		);
	}

	setApplicationCompanyOverride(data: AccessOverride) {
		return this._http.post(this._apiLink.setApplicationCompanyOverride, data);
	}
	setKbTopicCompanyOverride(data: AccessOverride) {
		return this._http.post(this._apiLink.setKbTopicCompanyOverride, data);
	}
	setFormCompanyOverride(data: AccessOverride) {
		return this._http.post(this._apiLink.setFormCompanyOverride, data);
	}
	setBoardCompanyOverride(data: AccessOverride) {
		return this._http.post(this._apiLink.setBoardCompanyOverride, data);
	}

	// user override
	getApplicationUserAccessOverride(options: any) {
		return this._http.get<GenericTable>(
			this._apiLink.getApplicationUserAccessOverride +
				formatQueryParams(options)
		);
	}
	getKBTopicUserAccessOverride(options: any) {
		return this._http.get<GenericTable>(
			this._apiLink.getKBTopicUserAccessOverride + formatQueryParams(options)
		);
	}
	getFormnUserAccessOverride(options: any) {
		return this._http.get<GenericTable>(
			this._apiLink.getFormnUserAccessOverride + formatQueryParams(options)
		);
	}
	getBoardUserAccessOverride(options: any) {
		return this._http.get<GenericTable>(
			this._apiLink.getBoardUserAccessOverride + formatQueryParams(options)
		);
	}

	setApplicationUserAccessOverride(data: AccessOverride) {
		return this._http.post(
			this._apiLink.setApplicationUserAccessOverride,
			data
		);
	}
	setKBTopicUserAccessOverride(data: AccessOverride) {
		return this._http.post(this._apiLink.setKBTopicUserAccessOverride, data);
	}
	setFormnUserAccessOverride(data: AccessOverride) {
		return this._http.post(this._apiLink.setFormnUserAccessOverride, data);
	}
	setBoardUserAccessOverride(data: AccessOverride) {
		return this._http.post(this._apiLink.setBoardUserAccessOverride, data);
	}

	reloadAuditTrail() {
		this._auditTrailUpdate.next(true);
	}
}

interface RolesAndPermissionState {
	mode: RnPMode;
	parent: 'default' | 'company';
	text: 'Create New' | 'Update' | 'Override' | 'View';
	backLink: string;
	companyId: number;
	url: string;
	userId?: number;
	isAdmin?: boolean;
	overrideBackLink?: string;
	companyBackLink?: string;
	isEditPageLoading: boolean;
	currentRoleId?: number;
	menuIndex?: number;
	isCreateHeaderSaved?: boolean;
}

interface UserRoleFormsQueryParams {
	page: number;
	pageSize: number;
	column: string;
	order: string;
	userId?: number;
	roleId?: number;
}

interface AccessOverride {
	userId: number;
	companyId?: number;
	roleId: number;
	isOverride: boolean;
}

type RnPMode = 'add' | 'edit' | 'override' | 'view' | 'company-override';
