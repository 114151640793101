import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription, take } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class DataModalService {
	private _modalDataSubject = new BehaviorSubject<IDataModal | null>(null);
	private _modalValueEmit = new Subject<boolean>();

	get modalData$(): Observable<IDataModal | null> {
		return this._modalDataSubject.asObservable();
	}

	get modalValue$(): Observable<boolean> {
		return this._modalValueEmit.asObservable();
	}

	showModal(modal: IDataModal): Observable<boolean> {
		this._modalDataSubject.next(modal);
		return this.modalValue$.pipe(take(1));
	}

	closeModal() {
		this._modalDataSubject.next(null);
	}

	emitValue(flag: boolean) {
		this._modalValueEmit.next(flag);
	}

	getDeleteModel(title: string, item: string, isWidget = false) {
		let message = `You are about to delete ${item} from your version history. This action is permanent and cannot be reversed.`;

		if (isWidget) {
			message = title.toLowerCase().includes('card')
				? `You are about to delete the ${item} card from the current tab. You can always add this back in later.`
				: `You are about to delete the ${item} tab. You can always add this back in later.`;
		}

		return {
			title: `Delete ${title}?`,
			hasTicket: false,
			message,
			messageSubText: '',
			type: 'danger',
			options: {
				yesText: 'Delete',
				noText: 'Cancel',
			},
		} as IDataModal;
	}

	getSaveModel(title: string, message: string) {
		title = title.toLowerCase().includes('publish')
			? `${title}?`
			: `Update ${title}?`;
		return {
			title,
			hasTicket: false,
			message,
			messageSubText: '',
			type: 'warn',
			options: {
				yesText: 'Confirm',
				noText: 'Cancel',
			},
		} as IDataModal;
	}

	getProfilePicModel(title: string, message: string) {
		return {
			title: `Delete ${title}?`,
			hasTicket: false,
			message,
			messageSubText: '',
			type: 'danger',
			options: {
				yesText: 'Delete',
				noText: 'Cancel',
			},
		} as IDataModal;
	}

	getDeleteModel2(title: string, item: string) {
		let message = `You are about to delete ${item} from the ${title}'s list.`;

		return {
			title: `Delete ${title}?`,
			hasTicket: false,
			message,
			messageSubText: '',
			type: 'danger',
			options: {
				yesText: 'Delete',
				noText: 'Cancel',
			},
		} as IDataModal;
	}

	openConfirmCancelTransaction(transactionNo: string) {
		const message = `You are about to remove transaction# ${transactionNo}.`;

		return {
			title: `Remove Transaction?`,
			hasTicket: false,
			message,
			messageSubText: '',
			type: 'danger',
			options: {
				yesText: 'Remove',
				noText: 'Cancel',
			},
		} as IDataModal;
	}

	openConfirmLeavePaymentSecurity() {
		const message = `Once you leave it will cancel those transactions that are not yet verified.`;

		return {
			title: `Leave this page?`,
			hasTicket: false,
			message,
			messageSubText: '',
			type: 'danger',
			options: {
				yesText: 'Yes',
				noText: 'No',
			},
		} as IDataModal;
	}
}

export interface IDataModal {
	title: string;

	hasTicket: boolean;
	ticketNumber?: string;
	ticketTime?: string;
	statusCode?: number;

	message: string;
	messageSubText: string;

	type:
		| 'danger'
		| 'success'
		| 'info'
		| 'warn'
		| 'none'
		| 'borderless'
		| 'overridePermission';
	hasNoClose?: boolean;
	options?: {
		yesText: string;
		noText: string;
		yesType?: 'danger' | 'success' | 'info' | 'warn';
	};
	altMessage?: string;
	template?: string;
	templateData?: { [prop: string]: any };
}
