<div
	*ngIf="totalItems > 0"
	class="q-d-flex q-f-wrap q-jc-center q-f-row-reverse q-ai-center q-cg-16 q-rg-8"
>
	<div class="q-d-flex q-g-8 q-m-auto" style="overflow-x: auto">
		<div
			(click)="onPageChange(page - 1)"
			(keyup.enter)="onPageChange(page - 1)"
			[class.disabled]="page === 1"
			class="page-item q-d-flex q-ai-center q-jc-center q-pointer"
			tabindex="0"
		>
			<mat-icon svgIcon="chevron-left"></mat-icon>
		</div>

		<div
			*ngFor="let p of pagination"
			(click)="onPageChange(p, true)"
			(keyup.enter)="onPageChange(p, true)"
			[class.active-page]="page === p"
			class="page-item font-b1 font-w-700 font-h-normal q-d-flex q-ai-center q-jc-center q-pointer"
			tabindex="0"
		>
			{{ p }}
		</div>

		<div
			(click)="onPageChange(page + 1)"
			(keyup.enter)="onPageChange(page + 1)"
			[class.disabled]="page + 1 > totalPages"
			class="page-item q-d-flex q-ai-center q-jc-center q-pointer"
			tabindex="0"
		>
			<mat-icon svgIcon="chevron-right"></mat-icon>
		</div>
	</div>

	<span class="font-b3">
		Showing {{ size * (page - 1) + 1 }} -
		{{ page == totalPages ? totalItems : size * page }} of
		{{ totalItems }}
	</span>
</div>
