<div class="table-container" [class.shared-ml-none]="isForReports">
	<div class="table-wrapper scroll-v2" #wrapper>
		<app-quick-filter
			*ngIf="quickFilter !== undefined"
			[url]="quickFilter.url"
			[quickFilterOptions]="quickFilter.optionList"
			[statusIds]="quickFilter.statusIds"
			[selectedType]="
				quickFilter.selectedType ? quickFilter.selectedType : 'multiple'
			"
			(onSelectIds)="quickFilter.onSelectIds($event)"
			class="scroll-v2"
		></app-quick-filter>

		<table
			mat-table
			matSort
			matSortDisableClear="true"
			[matSortActive]="column"
			[matSortDirection]="order"
			[dataSource]="(dataSource$ | async)!"
		>
			<ng-container *ngFor="let config of tableConfig">
				<ng-container *ngIf="config.isPinned">
					<ng-container
						[sticky]="
							config.isPinned &&
							(!config.unpinWidth || config.unpinWidth < wrapper.clientWidth)
						"
						matColumnDef="{{ config.displayedColumns }}"
					>
						<th
							*matHeaderCellDef="let row; let i = index"
							mat-header-cell
							mat-sort-header
							[disabled]="!config.isSortable"
							class="font-b1"
							[ngClass]="{
								'q-default': config.isCustomizeFirstColumnContent,
								'is-date': config.isDate,
								'is-number': config.isNumberOnly
							}"
							(click)="
								config.isCustomizeFirstColumnContent
									? $event.stopPropagation()
									: ''
							"
							[ngStyle]="{
								'max-width': setMaxWidth(config),
								width: setWidth(config.maxWidth)
							}"
						>
							<ng-container
								*ngIf="
									config.isCustomizeFirstColumnContent;
									else defaultFirstHeaderColumnContent
								"
							>
								<ng-container
									*ngTemplateOutlet="
										customizeFirstHeaderColumnRef;
										context: { $implicit: row, index: i }
									"
								>
								</ng-container>
							</ng-container>

							<ng-template #defaultFirstHeaderColumnContent>
								{{ config.tableHeader }}
							</ng-template>
						</th>

						<td
							mat-cell
							*matCellDef="let row; let i = index"
							class="font-b1"
							(click)="
								config.isCustomizeFirstColumnContent
									? $event.stopPropagation()
									: ''
							"
							[customTooltip]="tooltipTemplate"
							[tooltipData]="row"
							[isTooltipEnabled]="enabledTooltip"
							[ngStyle]="{
								'max-width': setMaxWidth(config),
								width: setWidth(config.maxWidth)
							}"
						>
							<ng-container
								*ngIf="
									isNullorEmpty(row[config.displayedColumns]) &&
									!config.isLockUnlockIcon &&
									!config.isForDirectoryOnly &&
									!config.isCustomizeFirstColumnContent
								"
							>
								<div class="em-dash">&#8212;</div>
							</ng-container>

							<ng-container
								*ngIf="
									!isNullorEmpty(row[config.displayedColumns]) ||
									config.isForDirectoryOnly ||
									config.isCustomizeFirstColumnContent ||
									config.isLockUnlockIcon
								"
							>
								<ng-container
									*ngIf="
										config.isCustomizeFirstColumnContent;
										else defaultFirstTDColumnContent
									"
								>
									<ng-container
										*ngTemplateOutlet="
											customizeFirstColumnTableDataRef;
											context: { $implicit: row, index: i }
										"
									>
									</ng-container>
								</ng-container>

								<ng-template #defaultFirstTDColumnContent>
									<ng-container *ngIf="config.isLockUnlockIcon">
										<div
											class="imp-parent justify-content-center d-flex"
											[class.imp-parent-disabled]="
												(!visibilityOption &&
													(isCustom === false || dataOptions?.groupId)) ||
												!isAccessEnabled
											"
										>
											<a
												class="edit imp-disabled-op"
												[tooltipContentV2]="{ message: 'Change Access' }"
												[class.disabled]="
													(!visibilityOption &&
														(isCustom === false || dataOptions?.groupId)) ||
													!isAccessEnabled
												"
												(click)="$event.stopPropagation(); onClick(row)"
											>
												<img
													class="lock-icon"
													src="./../../../../../../assets/images/icons/{{
														row[dataOptions?.columnSettings?.fieldName!]
															? 'lock-unlocked-icon.svg'
															: 'lock-icon.svg'
													}}"
													alt=""
												/>
											</a>
										</div>
									</ng-container>

									<ng-container *ngIf="config.isDateTime">
										{{ row | convertedDate : config.displayedColumns }}
									</ng-container>

									<ng-container *ngIf="config.isDate">
										<div class="is-date">
											{{ row[config.displayedColumns] | customDate }}
										</div>
									</ng-container>

									<ng-container *ngIf="config.isShortTime">
										<div class="time-value font-b3">
											{{ row[config.displayedColumns] | date : 'shortTime' }}
										</div>
									</ng-container>

									<ng-container *ngIf="config.isForDirectoryOnly">
										<div class="q-d-flex q-ai-center q-g-16">
											<img
												image-error-detection
												[component]="'company-directory.component.html'"
												[src]="
													row.imagePath !== null && row.imagePath !== ''
														? row.imagePath
														: 'assets/images/profile-pic/profilePic4.png'
												"
												class="profile-pic"
											/>
											<div class="q-d-flex q-f-column q-jc-center">
												<h4>
													{{ getFullname(row.firstName, row.lastName) }}
												</h4>
												<p class="font-b1">
													{{ row.title === 'null' ? '' : row.title }}
												</p>
											</div>
										</div>
									</ng-container>

									<ng-container *ngIf="isPlainText(config)">
										<div
											class="d-flex gap-3 align-items-center"
											[ngClass]="{
												'is-number': config.isNumberOnly
											}"
										>
											<div>{{ row[config.displayedColumns] }}</div>

											<app-badge
												*ngIf="!row.isPublish && config.isTextWithBadge"
												class="ms-auto"
												[label]="'Draft'"
												[type]="'#D9CAFA'"
												[fontColor]="'#2C0C6E'"
											></app-badge>
										</div>
									</ng-container>
								</ng-template>
							</ng-container>
						</td>
					</ng-container>
				</ng-container>

				<ng-container *ngIf="config.isPinnedEnd">
					<ng-container
						[stickyEnd]="
							config.isPinnedEnd &&
							(!config.unpinWidth || config.unpinWidth < wrapper.clientWidth)
						"
						matColumnDef="{{ config.displayedColumns }}"
					>
						<th
							*matHeaderCellDef
							mat-header-cell
							class="font-b1"
							mat-sort-header
							[disabled]="!config.isSortable"
							[ngStyle]="{
								'max-width': setMaxWidth(config),
								width: setWidth(config.maxWidth)
							}"
						>
							{{ config.tableHeader }}
						</th>

						<td
							mat-cell
							*matCellDef="let row; let i = index"
							class="font-b1"
							[class.q-default]="config.isCustomizeActionColumnContent"
							(click)="
								config.isCustomizeActionColumnContent
									? $event.stopPropagation()
									: ''
							"
							[customTooltip]="tooltipTemplate"
							[tooltipData]="row"
							[isTooltipEnabled]="enabledTooltip"
							[ngStyle]="{
								'max-width': setMaxWidth(config),
								width: setWidth(config.maxWidth)
							}"
						>
							<ng-container *ngIf="config.isBadge">
								<app-badge
									class="is-badge"
									[label]="row[config.displayedColumns]"
									[type]="row.color"
									[fontColor]="row.fontColor"
								></app-badge>
							</ng-container>

							<ng-container *ngIf="config.isForCompanyAssetsTableOnly">
								<div class="d-flex">
									<ng-container
										*ngIf="
											inventoryService.checkValidOnlineOfflineStatus(
												row.onlineOffline
											);
											else unknown
										"
									>
										<online-offline-status
											[isOnline]="row.onlineOffline.toLowerCase() === 'online'"
										>
										</online-offline-status>
									</ng-container>
									<ng-template #unknown> Unknown </ng-template>
								</div>
							</ng-container>

							<ng-container *ngIf="config.isForEmailTemplateSettingsFontsOnly">
								<div
									[ngStyle]="{
										color: row['color'],
										'font-size': row['size'],
										'font-weight': row['weightValue']
									}"
								>
									{{ row[config.displayedColumns] }}
								</div>
							</ng-container>

							<ng-container
								*ngIf="config.isForEmailTemplateSettingsButtonsOnly"
							>
								<div class="buttons-template-style" [ngStyle]="row['styles']">
									{{ row[config.displayedColumns] }}
								</div>
							</ng-container>

							<ng-container
								*ngIf="
									config.isCustomizeActionColumnContent;
									else defaultActionColumnContent
								"
							>
								<ng-container
									*ngTemplateOutlet="
										customizeActionColumnRef;
										context: { $implicit: row, index: i }
									"
								>
								</ng-container>
							</ng-container>

							<ng-template #defaultActionColumnContent>
								<div
									*ngIf="config.buttons"
									class="actions-wrapper gap-3 imp-parent"
								>
									<ng-container *ngFor="let button of config.buttons">
										<ng-container [ngSwitch]="button.identifier">
											<ng-container *ngSwitchCase="pageAction.EXTERNAL_LINK">
												<span [tooltipContentV2]="{ message: !hasExternalUrl(row) ? button.disabledBtnTitle : button.title }">
													<a
														*ngIf="button.hasPermission"
														(click)="$event.stopPropagation(); openURL(row)"
														href="javascript:void(0)"
														class="edit imp-disabled-op q-pointer"
														[ngClass]="{ disabled: !hasExternalUrl(row) }"
													>
														<mat-icon [svgIcon]="button.icon"></mat-icon>
													</a>
												</span>
											</ng-container>

											<ng-container *ngSwitchCase="pageAction.IMPERSONATE">
												<a
													*ngIf="button.hasPermission"
													href="javascript:void(0)"
													class="edit imp-disabled-op"
													[tooltipContentV2]="{
														message: button.title || getTitle(row)
													}"
													[isShowTooltip]="!!(button.title || getTitle(row))"
													(click)="
														$event.stopPropagation();
														impersonateClick(row.id, row.isImpersonateDisabled)
													"
												>
													<mat-icon [svgIcon]="button.icon"></mat-icon>
												</a>
											</ng-container>

											<ng-container *ngSwitchCase="pageAction.VIEW">
												<a
													*ngIf="button.hasPermission"
													href="javascript:void(0)"
													class="edit imp-disabled-op"
													[tooltipContentV2]="{
														message: button.title || getTitle(row)
													}"
													(click)="
														$event.stopPropagation();
														onNavigate('./preview/' + row.id)
													"
												>
													<mat-icon [svgIcon]="button.icon"></mat-icon>
												</a>
											</ng-container>

											<ng-container *ngSwitchCase="pageAction.COPY">
												<a
													*ngIf="button.hasPermission"
													href="javascript:void(0)"
													class="edit imp-disabled-op"
													[tooltipContentV2]="{
														message: button.title || getTitle(row)
													}"
													(click)="$event.stopPropagation(); copyClick(row)"
												>
													<mat-icon [svgIcon]="button.icon"></mat-icon>
												</a>
											</ng-container>

											<ng-container *ngSwitchCase="pageAction.DELETE">
												<a
													*ngIf="button.hasPermission"
													href="javascript:void(0)"
													class="edit imp-disabled-op"
													[tooltipContentV2]="{
														message: button.title || getTitle(row)
													}"
													(click)="$event.stopPropagation(); deleteClick(row)"
												>
													<mat-icon [svgIcon]="button.icon"></mat-icon>
												</a>
											</ng-container>

											<ng-container *ngSwitchDefault>
												<a
													*ngIf="button.hasPermission"
													href="javascript:void(0)"
													class="edit imp-disabled-op"
													[tooltipContentV2]="{
														message: button.title || getTitle(row)
													}"
												>
													<mat-icon [svgIcon]="button.icon"></mat-icon>
												</a>
											</ng-container>
										</ng-container>
									</ng-container>
								</div>
							</ng-template>
						</td>
					</ng-container>
				</ng-container>

				<ng-container *ngIf="!config.isPinned && !config.isPinnedEnd">
					<ng-container matColumnDef="{{ config.displayedColumns }}">
						<th
							*matHeaderCellDef
							mat-header-cell
							mat-sort-header
							[disabled]="!config.isSortable"
							class="font-b1"
							[ngClass]="{
								'is-date': config.isDate,
								'is-number': config.isNumberOnly,
								'text-end': config.isCurrency,
								'justify-content-center': config.isLockUnlockIcon
							}"
							[ngStyle]="{
								'max-width': setMaxWidth(config),
								width: config.isForInvoiceAccountActivityTableOnly
									? 'fit-content'
									: setWidth(config.maxWidth)
							}"
						>
							<ng-container *ngIf="!config.isCheckboxHeader">
								{{ config.tableHeader }}
							</ng-container>

							<ng-container *ngIf="config.isCheckboxHeader">
								<div class="form-check d-flex align-items-center">
									<input
										class="form-check-input imp-disabled-op q-pointer"
										type="checkbox"
										[checked]="notificationsProp.hasCheck ? true : false"
										(click)="toggleCheckAll()"
										[ngClass]="{
											minus: !notificationsProp.isAllCheck,
											check: notificationsProp.isAllCheck
										}"
										[disabled]="notificationsProp.isDisabled"
									/>
								</div>
							</ng-container>
						</th>

						<td
							mat-cell
							*matCellDef="let row"
							class="font-b1"
							[customTooltip]="tooltipTemplate"
							[tooltipData]="row"
							[isTooltipEnabled]="enabledTooltip"
							[ngClass]="{ 'table-icon': config.isImageUrl }"
							[ngStyle]="{
								'max-width': setMaxWidth(config),
								width: config.isForInvoiceAccountActivityTableOnly
									? 'fit-content'
									: setWidth(config.maxWidth)
							}"
						>
							<ng-container *ngIf="config.isCheckboxRow">
								<div class="form-check d-flex align-items-center">
									<input
										class="form-check-input imp-disabled-op q-pointer"
										type="checkbox"
										[checked]="parseStrToBool(row.isEnabled)"
										(click)="toggleCheckbox(row)"
										[ngClass]="{ check: parseStrToBool(row.isEnabled) }"
										[disabled]="notificationsProp.isDisabled"
									/>
								</div>
							</ng-container>

							<ng-container
								*ngIf="
									isNullorEmpty(row[config.displayedColumns]) &&
									!config.buttons &&
									!config.isLockUnlockIcon &&
									!config.isForInvoiceCreditsTableOnly &&
									!config.isForInvoicePaymentHistoryTableOnly &&
									!config.isForInvoiceAccountActivityTableOnly &&
									!config.isForKBOnly
								"
							>
								<div class="em-dash">&#8212;</div>
							</ng-container>

							<ng-container
								*ngIf="
									!isNullorEmpty(row[config.displayedColumns]) ||
									config.isNaOrData ||
									config.isLockUnlockIcon ||
									config.buttons ||
									config.isForInvoiceCreditsTableOnly ||
									config.isForInvoicePaymentHistoryTableOnly ||
									config.isForInvoiceAccountActivityTableOnly ||
									config.isForKBOnly
								"
							>
								<ng-container *ngIf="config.isCrossCheck">
									<app-cross-check
										[isCheck]="row[config.displayedColumns]"
									></app-cross-check>
								</ng-container>

								<ng-container *ngIf="config.isForKBOnly">
									<ul class="list-unstyled d-inline-flex d-flex m-0">
										<li>
											<app-local-figma-icon-img
												[filename]="'thumbs-up-green'"
												[width]="24"
												[height]="24"
												[hasSpaceOnRight]="true"
											></app-local-figma-icon-img>
											<span class="text-start">{{ row.likes }}</span>
										</li>

										<li>
											<app-local-figma-icon-img
												[filename]="'thumbs-down-red'"
												[width]="24"
												[height]="24"
												[hasSpaceOnRight]="true"
											></app-local-figma-icon-img>
											<span class="text-start">{{ row.dislikes }}</span>
										</li>
									</ul>
								</ng-container>

								<ng-container *ngIf="config.buttons">
									<div class="actions-wrapper gap-3 imp-parent">
										<ng-container *ngFor="let button of config.buttons">
											<ng-container [ngSwitch]="button.identifier">
												<ng-container *ngSwitchCase="pageAction.EXTERNAL_LINK">
													<a
														*ngIf="button.hasPermission"
														(click)="$event.stopPropagation(); openURL(row)"
														href="javascript:void(0)"
														class="edit imp-disabled-op q-pointer"
														[ngClass]="{ disabled: !hasExternalUrl(row) }"
														[tooltipContentV2]="{ message: button.title }"
													>
														<mat-icon [svgIcon]="button.icon"></mat-icon>
													</a>
												</ng-container>

												<ng-container *ngSwitchCase="pageAction.IMPERSONATE">
													<a
														*ngIf="button.hasPermission"
														href="javascript:void(0)"
														class="edit imp-disabled-op"
														[tooltipContentV2]="{
															message: button.title || getTitle(row)
														}"
														(click)="
															$event.stopPropagation();
															impersonateClick(
																row.id,
																row.isImpersonateDisabled
															)
														"
													>
														<mat-icon [svgIcon]="button.icon"></mat-icon>
													</a>
												</ng-container>

												<ng-container *ngSwitchCase="pageAction.COPY">
													<a
														*ngIf="button.hasPermission"
														href="javascript:void(0)"
														class="edit imp-disabled-op"
														[tooltipContentV2]="{
															message: button.title || getTitle(row)
														}"
														(click)="$event.stopPropagation(); copyClick(row)"
													>
														<mat-icon [svgIcon]="button.icon"></mat-icon>
													</a>
												</ng-container>

												<ng-container *ngSwitchCase="pageAction.DELETE">
													<a
														*ngIf="
															button.hasPermission ||
															(config.isForRequestFormOnly &&
																row.companyId === selectedCompanyId)
														"
														href="javascript:void(0)"
														class="edit imp-disabled-op"
														[tooltipContentV2]="{
															message: button.title || getTitle(row)
														}"
														(click)="$event.stopPropagation(); deleteClick(row)"
													>
														<mat-icon [svgIcon]="button.icon"></mat-icon>
													</a>
												</ng-container>

												<ng-container *ngSwitchDefault>
													<a
														*ngIf="button.hasPermission"
														href="javascript:void(0)"
														class="edit imp-disabled-op"
														[tooltipContentV2]="{
															message: button.title || getTitle(row)
														}"
													>
														<mat-icon [svgIcon]="button.icon"></mat-icon>
													</a>
												</ng-container>
											</ng-container>
										</ng-container>
									</div>
								</ng-container>

								<ng-container *ngIf="config.isLockUnlockIcon">
									<div
										class="imp-parent justify-content-center d-flex"
										[class.imp-parent-disabled]="
											(!visibilityOption &&
												(isCustom === false || dataOptions?.groupId)) ||
											!isAccessEnabled
										"
									>
										<a
											class="edit imp-disabled-op"
											[tooltipContentV2]="{ message: 'Change Access' }"
											[class.disabled]="
												(!visibilityOption &&
													(isCustom === false || dataOptions?.groupId)) ||
												!isAccessEnabled
											"
											(click)="$event.stopPropagation(); onClick(row)"
										>
											<img
												class="lock-icon"
												[src]="getLockUnlockIcon(row)"
												alt=""
											/>
										</a>
									</div>
								</ng-container>

								<ng-container *ngIf="config.isDateTime">
									<ng-container *ngIf="config.hasNewMessageIndicator">
										<div class="d-flex align-items-center gap-3">
											<div>
												{{ row | convertedDate : config.displayedColumns }}
											</div>
											<div
												*ngIf="row.isUpdated"
												class="has-new-update-indicator"
											></div>
										</div>
									</ng-container>

									<ng-container *ngIf="!config.hasNewMessageIndicator">
										{{ row | convertedDate : config.displayedColumns }}
									</ng-container>
								</ng-container>

								<ng-container *ngIf="config.isDate">
									<div
										class="is-date"
										[ngStyle]="{
											'max-width': setMaxWidth(config),
											width: config.isForInvoiceAccountActivityTableOnly
												? 'fit-content'
												: setWidth(config.maxWidth)
										}"
									>
										{{ row[config.displayedColumns] | customDate }}
									</div>
								</ng-container>

								<ng-container *ngIf="config.isShortTime">
									<div class="time-value font-b1">
										{{ row[config.displayedColumns] | date : 'shortTime' }}
									</div>
								</ng-container>

								<ng-container *ngIf="config.isCurrency">
									<div class="text-end">
										{{ row[config.displayedColumns] | currency }}
									</div>
								</ng-container>

								<ng-container *ngIf="config.isBadge">
									<ng-container *ngIf="row.status">
										<span
											class="com-badge font-b3 font-w-600 font-h-normal {{
												getStatusColor(row.status)
											}}"
										>
											{{ row.status }}
										</span>
									</ng-container>

									<ng-container *ngIf="!row.status">
										<app-badge
											class="is-badge"
											[label]="row[config.displayedColumns]"
											[type]="row.color"
											[fontColor]="row.fontColor"
										></app-badge>
									</ng-container>
								</ng-container>

								<ng-container *ngIf="config.isIcon">
									<app-embed-figma
										[data]="row"
										[hasSpaceOnRight]="false"
									></app-embed-figma>
								</ng-container>

								<ng-container *ngIf="config.isForCompanyAssetsTableOnly">
									<div class="d-flex">
										<ng-container
											*ngIf="
												inventoryService.checkValidOnlineOfflineStatus(
													row.onlineOffline
												);
												else unknown
											"
										>
											<online-offline-status
												[isOnline]="
													row.onlineOffline.toLowerCase() === 'online'
												"
											>
											</online-offline-status>
										</ng-container>
										<ng-template #unknown> Unknown </ng-template>
									</div>
								</ng-container>

								<ng-container
									*ngIf="config.isForEmailTemplateSettingsFontsOnly"
								>
									<div
										[ngStyle]="{
											color: row['color'],
											'font-size': row['size'],
											'font-weight': row['weightValue']
										}"
									>
										{{ row[config.displayedColumns] }}
									</div>
								</ng-container>

								<ng-container
									*ngIf="config.isForEmailTemplateSettingsButtonsOnly"
								>
									<div class="buttons-template-style" [ngStyle]="row['styles']">
										{{ row[config.displayedColumns] }}
									</div>
								</ng-container>

								<ng-container *ngIf="config.isForInvoiceCreditsTableOnly">
									<app-cross-check
										[isCheck]="
											!(
												(row.isSettled || row.isSettled === null) &&
												row.billAmount > 0
											)
										"
									>
									</app-cross-check>
								</ng-container>

								<ng-container
									*ngIf="
										config.isForInvoiceAccountActivityTableOnly &&
										!config.isDate &&
										!config.isShortTime
									"
								>
									<div class="q-d-flex q-ai-center">
										<div class="q-w-100">
											<div class="message-value font-b1">
												<ng-container
													*ngIf="row.initiatedBy && row.status === 'success'"
													>{{ row.initiatedBy }}
												</ng-container>

												{{ row[config.displayedColumns] }}

												<div
													*ngIf="row['transactionId']"
													class="account-activity-transaction-id"
												>
													Transaction ID: {{ row['transactionId'] }}
												</div>
											</div>
										</div>

										<ng-container *ngIf="row.status === 'error'">
											<mat-icon
												svgIcon="alert-triangle"
												style="stroke: #ce3a2d; width: 24px; height: 25px"
											></mat-icon>
										</ng-container>
									</div>
								</ng-container>

								<ng-container
									*ngIf="config.isForInvoicePaymentHistoryTableOnly"
								>
									<div class="q-right">
										{{ row.amountPaid + row.creditsApplied | currency }}
									</div>
								</ng-container>

								<ng-container *ngIf="config.isForInvoiceStatusColumnOnly">
									<div class="d-flex align-items-center">
										<div class="status-con q-p-4">
											<app-badge
												[label]="row.status"
												[type]="row.color"
												[fontColor]="row.fontColor"
											></app-badge>
										</div>
									</div>

									<ng-container
										*ngIf="
											row.type === 'Invoice' &&
											row.hasAutoPayError &&
											row.comment
										"
									>
										<div
											*ngIf="![null, ''].includes(row.paymentIntentId)"
											class="mt-1 d-table"
											[tooltipContentV2]="{
												innerHtml: autoPayMessage(row.comment)
											}"
										>
											<app-badge
												label="AUTOPAY FAILED"
												type="#ffe7f2"
												fontColor="#b3093c"
												[filename]="'alert-triangle-red'"
												[height]="13"
												[width]="13"
											>
											</app-badge>
										</div>

										<div
											*ngIf="[null, ''].includes(row.paymentIntentId)"
											class="mt-1 d-table"
											[tooltipContentV2]="{
												innerHtml: autoPayMessage(row.comment)
											}"
										>
											<app-badge
												label="UNABLED TO PROCESS"
												type="#ebeef1"
												fontColor="#545969"
												[filename]="'alert-triangle-grey'"
												[height]="13"
												[width]="13"
											>
											</app-badge>
										</div>
									</ng-container>
								</ng-container>

								<ng-container *ngIf="config.isImageUrl">
									<div
										[ngStyle]="{
											'min-width': setWidth(config.maxWidth)
										}"
									>
										<img
											image-error-detection
											[component]="pageUrl"
											class="img-fluid icon-small"
											[src]="row[config.displayedColumns]"
										/>
										<img
											[src]="brokenImage"
											class="img-fluid icon-small d-none"
											alt="broken image"
										/>
									</div>
								</ng-container>

								<ng-container *ngIf="config.isSecondsTime">
									<div class="time-value font-b1">
										{{
											this.reportApiService.convertHuman(
												row[config.displayedColumns],
												'double'
											)
										}}
									</div>
								</ng-container>

								<ng-container *ngIf="config.isAgeTime">
									<div class="time-value font-b1">
										{{
											row[config.displayedColumns] > 0
												? '~' +
												  this.reportApiService.convertHuman(
														row[config.displayedColumns],
														'single'
												  )
												: 'N/A'
										}}
									</div>
								</ng-container>

								<ng-container *ngIf="config.isYesOrNo">
									<div class="time-value font-b1">
										{{ row[config.displayedColumns] > 0 ? 'Yes' : 'No' }}
									</div>
								</ng-container>

								<ng-container *ngIf="config.isNaOrData">
									<div class="time-value font-b1">
										{{
											row[config.displayedColumns]
												? row[config.displayedColumns]
												: 'N/A'
										}}
									</div>
								</ng-container>

								<ng-container *ngIf="config.isUrl">
									<a
										class="nav-text"
										(click)="
											$event.stopPropagation();
											navigate(row, config.displayedColumns)
										"
									>
										{{ row[config.displayedColumns] }}
									</a>
								</ng-container>

								<ng-container *ngIf="config.isSecurityRisk">
									<ng-container
										*ngFor="
											let risk of row[config.displayedColumns];
											let i = index
										"
									>
										<div *ngIf="i < 3" [ngClass]="risk ? '' : 'em-dash'">
											{{ risk ? risk : '&#8212;' }}
										</div>
									</ng-container>
									<span class="db-tooltip-container">
										<p *ngIf="row[config.displayedColumns].length > 3">
											<a
												>View
												{{ row[config.displayedColumns].length - 3 }} other
												security issues</a
											>
										</p>
										<div class="db-tooltip security-issues">
											<p
												class="loop-text"
												*ngFor="let m of row[config.displayedColumns].slice(3)"
											>
												{{ m }}
											</p>
										</div>
									</span>
								</ng-container>

								<ng-container *ngIf="config.isUserColumn">
									<div class="q-d-flex q-ai-center q-g-16">
										<img
											image-error-detection
											[component]="'users-ticket.component.html'"
											[src]="
												row.image !== null && row.image !== ''
													? row.image
													: 'assets/images/profile-pic/profilePic.png'
											"
											class="profile-pic"
										/>
										<span>{{ row[config.displayedColumns] }}</span>
									</div>
								</ng-container>

								<ng-container *ngIf="config.isLastSeenDate">
									<div class="time-value font-b1">
										{{
											row[config.displayedColumns]
												? this.getDateDifference(row[config.displayedColumns]) +
												  ' ' +
												  (this.getDateDifference(
														row[config.displayedColumns]
												  ) > 1
														? 'Days Ago'
														: 'Day Ago')
												: 'N/A'
										}}
									</div>
								</ng-container>

								<ng-container *ngIf="config.isTimeSpent">
									<div class="time-value font-b1 seconds-time">
										<span class="left">
											{{
												this.reportApiService.convertHuman(
													row[config.displayedColumns],
													'double'
												)
											}}
										</span>
										<span class="right">
											({{ row[config.secondaryColumn!] + '%' }})</span
										>
									</div>
								</ng-container>

								<ng-container *ngIf="isPlainText(config)">
									<div
										[ngClass]="{
											'is-number': config.isNumberOnly,
											'is-satscore': config.isSatScore
										}"
									>
										<ng-container
											*ngIf="config.isPhoneNumber; else defaultPlainText"
										>
											{{ formatPhoneNumber(row[config.displayedColumns]) }}
										</ng-container>
										<!-- smiley -->

										<ng-container *ngIf="config.isSatScore">
											<img
												class="lock-icon"
												style="width: 21px; height: 21px"
												[src]="
													'./../../../../../../assets/icons/' +
													getSmileyImage(row[config.displayedColumns]) +
													'.png'
												"
												alt=""
											/>
										</ng-container>
										<ng-template #defaultPlainText>
											<span
												*ngIf="config.isSatScore"
												style="margin-right: 18px"
											>
												{{ row[config.displayedColumns] }}%</span
											>
											<span *ngIf="!config.isSatScore">
												{{ row[config.displayedColumns] }}</span
											>
										</ng-template>
									</div>
								</ng-container>
							</ng-container>
						</td>
					</ng-container>
				</ng-container>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr
				#tableRow
				mat-row
				*matRowDef="
					let row;
					columns: displayedColumns;
					let odd = odd;
					let i = index
				"
				class="imp-table-disabled"
				[class.q-pointer]="
					((isQuoteLinkEnabled && !!row.quoteURL) || !isPageUrlForQutoes) &&
					!isPageUrlForAssets &&
					!isPageUrlForInvoiceAccountActivity &&
					!isPageUrlForInvoicePaymentHistory &&
					!isPageUrlForInvoiceCreditsTab &&
					!isPageUrlForFontsTemplateSettings &&
					!isPageUrlForButtonsTemplateSettings
				"
				[ngClass]="{ 'has-new-update': row.isUpdated }"
				[class.table-row-border-none]="isPageUrlForInvoiceAccountActivity"
				[class.striped-row]="odd && isPageUrlForInvoiceAccountActivity"
				(click)="onRowClick(row)"
				trigger="click"
				[attr.data-index]="i"
				data-bs-customClass="custom-tooltip"
			></tr>

			<tr class="mat-row" *matNoDataRow>
				<table-skeleton
					*ngIf="paginator"
					[isLoading]="!!(isLoading$ | async)"
					[colCount]="displayedColumns.length"
					[pageSize]="paginator.size"
				></table-skeleton>
			</tr>
		</table>
	</div>
</div>
<!--  -->
<div [hidden]="isPaginationHidden">
	<div class="table-paginator-container" [hidden]="totalItems === 0">
		<div class="paginator-group">
			<table-paginator
				[totalItems]="totalItems"
				[pageSizes]="pageSizes"
				(pageChange)="updatePage()"
			></table-paginator>
		</div>

		<div class="item-per-page-group">
			<app-item-per-page
				(sizeChange)="updateSize()"
				[totalItems]="totalItems"
			></app-item-per-page>
		</div>
	</div>
</div>

<ng-template #tooltipTemplate>
	<div
		class="custom-tooltip-content-v2 tooltip bs-tooltip-auto ticket-tooltip view-ticket-tooltip"
		[style.visibility]="isTooltipLoading ? 'hidden' : 'visible'"
	>
		<div class="tooltip-inner">
			<ng-container *ngFor="let tol of tooltipData; let i = index">
				<div class="tooltip-inner__header">
					<span class="tooltip-inner__header--name">{{ tol.name }}</span>
					<span class="tooltip-inner__header--date">
						{{ tol.startDate | date }}
					</span>
				</div>
				<div class="tooltip-inner__message">{{ tol.note }}</div>
				<div
					class="tooltip-inner__border"
					*ngIf="tooltipData.length !== 1 && tooltipData.length !== i + 1"
				></div>
			</ng-container>
		</div>
	</div>
</ng-template>
