import { ReportApiService } from '@app/modules/portal-usage/pages/dashboard-report/services/report-api.service';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ContentChild,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	Output,
	SimpleChanges,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { ItemPerPageComponent } from '../components/paginator/item-per-page/item-per-page.component';
import { PaginatorComponent } from '../components/paginator/paginator.component';

import { CustomTooltipV2Directive } from '../directives/custom-tooltip-v2/custom-tooltip-v2.directive';

import {
	debounceTime,
	distinctUntilChanged,
	Observable,
	startWith,
	Subscription,
	take,
	tap,
} from 'rxjs';
import { MatRow } from '@angular/material/table';
import { MatSort, SortDirection } from '@angular/material/sort';

import { ColorService } from '../services/color.service';
import { CustomTooltipV2Service } from '../directives/custom-tooltip-v2/custom-tooltip-v2.service';
import { InventoryService } from '@app/modules/inventory/inventory.service';
import { NewTableSharedService } from './new-table-shared.service';
import { NotificationsClienthubService } from '../services/notifications-clienthub.service';
import { TicketService } from './../../modules/service-and-support/ticket.service';
import { UserService } from '@app/core/services/user.service';

import { clone } from '../utilities/helper';
import { OptionsModel } from '@app/shared/functions/options';

import {
	FilterUrl,
	InvoiceTab,
	PageAction,
	PaginationConstants,
	Permission,
	TableMessages,
} from '../constants';

import {
	ClientAdminRoutes,
	SPAdminRoutes,
	UserRoutes,
} from './new-table-shared.enum';

import {
	IAction,
	INewComment,
	IPageOption,
	IQuickFilter,
	ITableConfig,
} from './new-table-shared.interface';
import { IPageState } from '../interfaces/page-state.interface';
import { QuickFilterComponent } from '../components/quick-filter/quick-filter.component';

@Component({
	selector: 'app-new-table-shared',
	templateUrl: './new-table-shared.component.html',
	styleUrls: ['./new-table-shared.component.scss'],
})
export class NewTableSharedComponent
	implements OnChanges, AfterViewInit, OnDestroy
{
	@ViewChild(PaginatorComponent) paginator: PaginatorComponent;
	@ViewChild(ItemPerPageComponent) itemsPerPage: ItemPerPageComponent;
	@ViewChild(MatSort) sort: MatSort;
	@ViewChild(CustomTooltipV2Directive)
	customTooltipV2Directive!: CustomTooltipV2Directive;
	@ViewChild(QuickFilterComponent) quickFilterComponent: QuickFilterComponent;
	@ContentChild('customizeActionColumnRef')
	customizeActionColumnRef: TemplateRef<unknown>;
	@ContentChild('customizeFirstColumnTableDataRef')
	customizeFirstColumnTableDataRef: TemplateRef<unknown>;
	@ContentChild('customizeFirstHeaderColumnRef')
	customizeFirstHeaderColumnRef: TemplateRef<unknown>;
	@ViewChild('tableRow') tableRow: MatRow;
	@Input() searchFilters: Params | IPageState;
	@Input() quickFilter: IQuickFilter;
	@Input() loadingText: string;
	@Input() pageOptions: IPageOption;
	@Input() onClick: (args?: any) => void;
	@Input() copyClick: (args?: any) => void;
	@Input() deleteClick: (args?: any) => void;
	@Input() impersonateClick: (id: number, isImpDisabled?: boolean) => void;
	@Input() selectedCompanyId: number;
	@Input() isPaginationHidden: boolean = false;
	@Input() notificationsProp: any;
	@Input() toggleCheckbox: (args?: any) => void;
	@Input() toggleCheckAll: (args?: any) => void;
	@Input() isDisabled: boolean;
	@Input() isForReports: boolean = false;
	@Output() emitTotalItems = new EventEmitter<number>();
	@Output() emitData = new EventEmitter<any>();
	@Output() emitRow = new EventEmitter<{ [prop: string]: string }>();
	@Output() totalItemsChanged = new EventEmitter<number>();

	displayedColumns: string[] = [];
	tableConfig: ITableConfig[] = [];
	message = {
		noRecord: TableMessages.EmptyTable,
	};
	column: string;
	order: SortDirection;
	pageSizes = PaginationConstants.pageSizes;
	totalItems: number;

	quickFilterUrl = FilterUrl;
	brokenImage = 'assets/images/others/broken-image.png';
	pageUrl: string;

	dataSource$: Observable<any[]>;
	isLoading$: Observable<boolean>;
	totalItems$: Observable<number>;

	isQuoteLinkEnabled = this._userService.hasPermission([
		Permission.ShowQuoteLinkUser,
	]);

	tooltipData: any[] = [];
	selectedRowId: any;
	isTooltipLoading: boolean = false;
	isFirstLoad = true;

	url: string;
	companyId = '';

	private _subscription = new Subscription();
	private _prevSub?: Subscription;

	constructor(
		public colorService: ColorService,
		public reportApiService: ReportApiService,
		public inventoryService: InventoryService,
		private _activeRoute: ActivatedRoute,
		private _cd: ChangeDetectorRef,
		private _router: Router,
		private _customTooltipV2Service: CustomTooltipV2Service,
		private _newTableService: NewTableSharedService,
		private _notificationsClienthubService: NotificationsClienthubService,
		private _ticketService: TicketService,
		private _userService: UserService
	) {
		let tableConfig: ITableConfig[];

		this.url = this._router.url.split('?')[0];
		this._newTableService.initializePermission();

		const id = _activeRoute.snapshot.params['id'];

		if (id) {
			const filterByCompanyId = _activeRoute.snapshot.params['companyId'];

			if (filterByCompanyId) {
				tableConfig = this._newTableService.getTableConfigs(
					this.url,
					filterByCompanyId,
					id
				);
			} else {
				tableConfig = this._newTableService.getTableConfigs(this.url, id);
			}
		} else {
			if (_activeRoute.snapshot.params['companyId']) {
				this.companyId = _activeRoute.snapshot.params['companyId'];
				tableConfig = this._newTableService.getTableConfigs(
					this.url,
					this.companyId
				);
			} else {
				tableConfig = this._newTableService.getTableConfigs(this.url);
			}
		}

		this.pageUrl = this._router.url;
		this.tableConfig = tableConfig;
		this.displayedColumns = tableConfig
			.map((table) => {
				let displayedColumns = '';

				if (table.displayedColumns === 'action' && table.hasPermission) {
					displayedColumns = table.displayedColumns;
				}

				if (table.displayedColumns !== 'action') {
					displayedColumns = table.displayedColumns;
				}

				return displayedColumns;
			})
			.filter((displayedColumns) => displayedColumns !== '');
	}

	get isAccessEnabled(): boolean | undefined {
		return this.pageOptions?.isAccessEnabled || true;
	}

	get isCustom(): boolean | undefined {
		return this.pageOptions?.isCustom;
	}

	get visibilityOption(): boolean | undefined {
		return this.pageOptions?.visibilityOption;
	}

	get dataOptions(): OptionsModel | undefined {
		return this.pageOptions?.dataOptions;
	}

	get pageAction() {
		const { DELETE, VIEW, EXTERNAL_LINK, IMPERSONATE, COPY } = PageAction;
		return { DELETE, VIEW, EXTERNAL_LINK, IMPERSONATE, COPY };
	}

	get isPageUrlForTickets(): boolean {
		return this._router.url === UserRoutes.TICKETS;
	}

	get isPageUrlForQutoes(): boolean {
		return this._router.url === UserRoutes.QUOTES;
	}

	get isPageUrlForDashboard(): boolean {
		return this._router.url === UserRoutes.DASHBOARD;
	}

	get isPageUrlForAssets(): boolean {
		return this._router.url === UserRoutes.ASSETS;
	}

	get isPageUrlForInvoiceAccountActivity() {
		return (
			(this._router.url === UserRoutes.INVOICES_USER || this._router.url === UserRoutes.INVOICES_ADMIN) &&
			this._newTableService?.tabIndex === InvoiceTab.accountActivity
		);
	}

	get isPageUrlForInvoicePaymentHistory() {
		return (
			(this._router.url === UserRoutes.INVOICES_USER || this._router.url === UserRoutes.INVOICES_ADMIN) &&
			this._newTableService?.tabIndex === InvoiceTab.payments
		);
	}

	get isPageUrlForInvoiceCreditsTab() {
		return (
			(this._router.url === UserRoutes.INVOICES_USER || this._router.url === UserRoutes.INVOICES_ADMIN) &&
			this._newTableService?.tabIndex === InvoiceTab.credits
		);
	}

	get isPageUrlForInvoiceTab() {
		return (
			(this._router.url === UserRoutes.INVOICES_USER || this._router.url === UserRoutes.INVOICES_ADMIN) &&
			this._newTableService?.tabIndex === InvoiceTab.invoice
		);
	}

	get isPageUrlForFontsTemplateSettings(): boolean {
		return (
			this._router.url === SPAdminRoutes.COMMUNICATIONS_TEMPLATE_STYLES_FONTS
		);
	}

	get isPageUrlForButtonsTemplateSettings(): boolean {
		return (
			this._router.url === SPAdminRoutes.COMMUNICATIONS_TEMPLATE_STYLES_BUTTONS
		);
	}

	get isViewTicketTable() {
		return this._router.url === UserRoutes.TICKETS;
	}

	get enabledTooltip() {
		return this.isViewTicketTable;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['searchFilters'] && this.searchFilters !== undefined) {
			const searchFilters = this._getSorting();

			if (this.isFirstLoad) {
				this.isFirstLoad = false;
				this._initializeDataSource();
				this._totalItemsSubscription();
			}

			this.paginator.changePageWithoutEmit(searchFilters.page);
			this.fetchNewData(searchFilters);
		}
	}

	ngAfterViewInit() {
		this._initTooltipContent();
		this._sortSubscription();
		this._newTicketCommentSubscription();
		this._cd.detectChanges();
	}

	ngOnDestroy() {
		this._subscription.unsubscribe();
	}

	getLockUnlockIcon(row: any): string {
		let icon = 'lock-icon.svg';

		if (
			this.parseStrToBool(row?.isEnabled) ||
			row[this.dataOptions?.columnSettings?.fieldName!]
		) {
			icon = 'lock-unlocked-icon.svg';
		}

		return `assets/images/icons/${icon}`;
	}

	formatPhoneNumber(phone: string): string {
		const cleaned = ('' + phone).replace(/\D/g, '');
		const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
		let formattedPhone = phone;

		if (match) {
			formattedPhone = '(' + match[1] + ') ' + match[2] + '-' + match[3];
		}

		return formattedPhone;
	}

	setMaxWidth(config: ITableConfig): string {
		let width = config.maxWidth ? `${config.maxWidth}px` : '';

		if (
			config.isBadge ||
			config.isForInvoiceStatusColumnOnly ||
			config.isForInvoiceAccountActivityTableOnly
		) {
			width = '';
		}

		return width;
	}

	setWidth(num: number | undefined): string {
		return num ? `${num}px` : '';
	}

	isPlainText(config: ITableConfig): boolean {
		return (
			!config.isDateTime &&
			!config.isDate &&
			!config.isBadge &&
			!config.isCurrency &&
			!config.isShortTime &&
			!config.isIcon &&
			!config.isImageUrl &&
			!config.isCrossCheck &&
			!config.isSecondsTime &&
			!config.isTimeSpent &&
			!config.isAgeTime &&
			!config.isYesOrNo &&
			!config.isUrl &&
			!config.isSecurityRisk &&
			!config.isUserColumn &&
			!config.isLastSeenDate &&
			!config.isNaOrData &&
			/* For specific tables only */
			!config.isForCompanyAssetsTableOnly &&
			!config.isForInvoiceAccountActivityTableOnly &&
			!config.isForInvoicePaymentHistoryTableOnly &&
			!config.isForInvoiceCreditsTableOnly &&
			!config.isForInvoiceStatusColumnOnly &&
			!config.isForEmailTemplateSettingsFontsOnly &&
			!config.isForEmailTemplateSettingsButtonsOnly
		);
	}

	getStatusColor(status: string): string {
		let classColor = 'neutral';

		if (status.includes('Active')) classColor = 'success';
		else if (
			status.includes('IT Bundled') ||
			status.includes('Partner') ||
			status.includes('Cloud')
		)
			classColor = 'primary';
		else if (status.includes('Managed') || status.includes('Standard'))
			classColor = 'secondary';
		else if (
			status == 'No Longer Client' ||
			status.includes('Inactive') ||
			status == 'Exiting'
		)
			classColor = 'danger';
		else if (status.includes('Attention') || status.includes('Basic'))
			classColor = 'optimal';
		else if (
			status.includes('Gold') ||
			status.includes('Imported') ||
			status.includes('Premium')
		)
			classColor = 'warning';

		return classColor;
	}

	onRowClick(row: any) {
		/* Disabled row click on table */
		if (
			this.isPageUrlForAssets ||
			this.isPageUrlForInvoiceAccountActivity ||
			this.isPageUrlForInvoicePaymentHistory ||
			this.isPageUrlForInvoiceCreditsTab ||
			this.isPageUrlForFontsTemplateSettings ||
			this.isPageUrlForButtonsTemplateSettings
		)
			return;

		if (this.isPageUrlForQutoes) {
			if (this.isQuoteLinkEnabled) {
				this.openURL(row);
			}
		} else if (this.isPageUrlForDashboard) {
			this.emitRow.emit(row);
		} else {
			if (this.pageOptions) {
				const isCustom =
					this.pageOptions.isCustom === undefined
						? false
						: this.pageOptions.isCustom;

				switch (this.url) {
					case SPAdminRoutes.GLOBAL_KB:
					case `${SPAdminRoutes.COMPANY_KB}${this.companyId}`:
					case ClientAdminRoutes.KB:
						localStorage.setItem('kb_isCustom', String(isCustom));
						break;

					case SPAdminRoutes.GLOBAL_APPLICATION:
					case `${SPAdminRoutes.COMPANY_APPLICATION}${this.companyId}`:
					case ClientAdminRoutes.APPLICATION:
					case UserRoutes.APPLICATION:
						localStorage.setItem('application_isCustom', String(isCustom));
					default:
						break;
				}
			}

			const route = `${this._newTableService.getRowClickUrl(row.id)}`;
			let queryParams = this._getQueryParams(row);

			if (route) {
				this.onNavigate(route, queryParams);
			}
		}
	}

	parseStrToBool(data: string): boolean {
		let val = false;

		if (typeof data === 'boolean') {
			val = data;
		} else if (typeof data === 'string') {
			JSON.parse(data.toLowerCase());
		}

		return val;
	}

	onNavigate(route: string, queryParams = {}) {
		this._router.navigate([route], {
			relativeTo: this._activeRoute,
			queryParams,
		});
	}

	validateConfig(prop: boolean | IAction[] | undefined): boolean {
		let isAllow = true;

		if (typeof prop === 'undefined' && !Array.isArray(prop)) {
			isAllow = true;
		}

		if (typeof prop === 'boolean' || Array.isArray(prop)) {
			isAllow = false;
		}

		return isAllow;
	}

	isNullorEmpty(str: string): boolean {
		let isNullOrEmpty = false;

		if (str === null || str === undefined) {
			isNullOrEmpty = true;
		} else if (str === 'null' || str === 'undefined' || str === '') {
			isNullOrEmpty = true;
		}

		return isNullOrEmpty;
	}

	hasExternalUrl(row: any): boolean {
		let hasExternalUrl = false;

		if (row.quoteURL) {
			hasExternalUrl = true;
		} else if (row.connectWiseContactUrl) {
			hasExternalUrl = true;
		}

		return hasExternalUrl;
	}

	openURL(row: any) {
		let url = '';

		if (row.quoteURL) {
			url = row.quoteURL;
		} else if (row.connectWiseContactUrl) {
			url = row.connectWiseContactUrl;
		}

		if (!!url) {
			window.open(url, '__blank');
		}
	}

	getTransactionID(row: string): string {
		return '';
	}

	updateSize() {
		this.paginator.size = this.itemsPerPage.pageSize;
		this.paginator.setTotalPages();
		this.paginator.setPages();

		if (
			this.paginator.totalItems <
			this.itemsPerPage.pageSize * (this.paginator.page - 1) + 1
		) {
			this.paginator.changePageWithoutEmit(1);
			this.searchFilters.page = 1;
		}
		console.log(this.searchFilters.pageSize);
		console.log(this.itemsPerPage.pageSize);
		this.searchFilters.pageSize = this.itemsPerPage.pageSize;
		this.searchFilters.column = this.column;
		this.searchFilters.order = this.order; 
		this.fetchNewData(this.searchFilters);
	}

	updatePage() {
		const filters = clone(this.searchFilters);
		filters.page = this.paginator.page;
		filters.column = this.column;
		filters.order = this.order; 
		this.searchFilters = filters;
		this.fetchNewData(this.searchFilters);
	}

	fetchNewData(searchFilters: Params | IPageState) {
		const sub = this._newTableService.fetchNewData(
			searchFilters,
			this.isCustom === undefined ? false : this.isCustom,
			this._prevSub
		);

		if (sub) this._prevSub = sub;
	}

	getFullname(firstName: string, lastName: string): string {
		let fullname = `${lastName}, ${firstName}`;

		if (lastName === '' || lastName === null) {
			fullname = `${firstName}`;
		}

		return fullname;
	}

	getTitle(row: any): string {
		let title = '';

		switch (this._router.url) {
			case SPAdminRoutes.GLOBAL_DIRECTORY:
				title = row.isImpersonateDisabled
					? 'This user cannot login in the system as company is no longer client'
					: 'Impersonate';
				break;
		}

		return title;
	}

	autoPayMessage(message: string): string {
		return `<div>${message}</div> `;
	}

	extractword(str: any, start: any, end: any) {
		var startindex = str.indexOf(start);
		var endindex = str.indexOf(end, startindex);
		if (startindex != -1 && endindex != -1 && endindex > startindex)
			return str.substring(startindex, endindex);
	}

	private _initializeDataSource() {
		const { dataSource$, isLoading$, totalItems$ } =
			this._newTableService.getDataSource(this.isCustom);

		this.dataSource$ = dataSource$.pipe(
			tap((data) => {
				if (data.length > 0) {
					this.emitData.emit(data);
				}

				if (
					this.isPageUrlForTickets &&
					data.length > 0 &&
					this._ticketService.recentlyOpenedTicketNo
				) {
					data.forEach((ticket) => {
						if (ticket.id === this._ticketService.recentlyOpenedTicketNo) {
							ticket.isUpdated = false;
						}
					});
				}

				this._cd.detectChanges();
			})
		);
		this.isLoading$ = isLoading$;
		this.totalItems$ = totalItems$;
	}

	private _totalItemsSubscription() {
		this._subscription.add(
			this.totalItems$
				.pipe(
					tap((total) => {
						if (total !== this.totalItems) {
							this.totalItems = total;

							if (total === 0) {
							}

							if (this.searchFilters) {
								this.paginator.changePageWithoutEmit(this.searchFilters.page);
							}

							this.emitTotalItems.emit(total);
							this._cd.detectChanges();
						}

						this.isLoading$
							.pipe(debounceTime(100), take(1))
							.subscribe((isLoading) => {
								if (!isLoading) this.totalItemsChanged.emit(total);
							});
					})
				)
				.subscribe()
		);
	}

	private _sortSubscription() {
		this._subscription.add(
			this.sort.sortChange.subscribe(() => {
				const searchFilters = this._getSorting();
				this.fetchNewData(searchFilters);
			})
		);
	}

	private _newTicketCommentSubscription() {
		if (this.isPageUrlForTickets) {
			this._subscription.add(
				this._notificationsClienthubService.newTicketCommentNotificationReceived
					.pipe(
						tap((notification: INewComment) => {
							this.dataSource$
								.pipe(
									tap((data) => {
										if (data.length > 0) {
											data.forEach((ticket) => {
												if (
													ticket.id === notification.id &&
													notification.userId !== this._userService.userId
												) {
													ticket.isUpdated = true;
												}
											});
										}
									})
								)
								.subscribe(() => this._cd.detectChanges());
						})
					)
					.subscribe()
			);
		}
	}

	private _getSorting(): Params | IPageState {
		const searchFilters = clone(this.searchFilters);

		this._setSorting(searchFilters);
		searchFilters.column = this.column;
		searchFilters.order = this.order;
		return searchFilters;
	}

	private _setSorting(searchFilters: Params | IPageState) {
		if (this.order && this.column) {
			this.column = this.sort.active;
			this.order = this.sort.direction;
		} else {
			this.column = searchFilters.column;
			this.order = searchFilters.order;
			this.itemsPerPage.pageSize = searchFilters.pageSize;
		}
	}

	private _initTooltipContent() {
		if (this.enabledTooltip) {
			this._subscription.add(
				this._customTooltipV2Service.rowData
					.pipe(
						startWith(''),
						tap((row: any) => {
							if (this.selectedRowId !== row.id) {
								this.isTooltipLoading = true;
							} else {
								this.isTooltipLoading = false;
							}
						}),
						debounceTime(1500),
						distinctUntilChanged()
					)
					.subscribe({
						next: (row: any) => {
							this.isTooltipLoading = true;
							this.selectedRowId = row.id;

							if (row) {
								this._subscription.add(
									this._ticketService.getTicketTooltip(row.id).subscribe({
										next: (result: any) => {
											this.tooltipData = result;
											this.isTooltipLoading = false;
										},
									})
								);
							}
						},
					})
			);
		} else {
			this.tooltipData = [];
		}
	}

	private _getQueryParams(row: any) {
		let queryParams = {};

		switch (this.url) {
			case SPAdminRoutes.GLOBAL_KB:
			case `${SPAdminRoutes.COMPANY_KB}${this.companyId}`:
			case ClientAdminRoutes.KB:
				queryParams = { topic: btoa(row.id) };
				break;

			default:
				break;
		}
		return queryParams;
	}

	getSmileyImage(val: number): string {
		if (val === 0) {
			return 'Icon-glad';
		} else if (val <= 29) {
			return 'Icon-sad';
		} else if (val <= 39) {
			return 'Icon-lonely';
		} else if (val <= 59) {
			return 'Icon-neutral';
		} else if (val <= 79) {
			return 'Icon-glad';
		} else {
			return 'Icon-happy';
		}
	}

	navigate(row: any, column: string) {
		let url = '';

		if (column == 'id') {
			url = row.idURL;
		} else if (column == 'kbId') {
			url = row.kbURL;
		}

		if (!!url) {
			window.open(url, '__blank');
		}
	}

	getDateDifference(dateString: string): number {
		const inputDate: Date = new Date(dateString);
		const today: Date = new Date();

		// Calculate the difference in milliseconds
		const differenceMs: number = today.getTime() - inputDate.getTime();

		// Convert milliseconds to days and return the result
		return Math.floor(differenceMs / (1000 * 60 * 60 * 24));
	}

	onFetchQuickFilter() {
		this.quickFilterComponent?.getQuickFilter();
	}
}
