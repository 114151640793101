import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NavigationEnd, Params, Router } from '@angular/router';

import {
	BehaviorSubject,
	filter,
	finalize,
	map,
	Observable,
	of,
	ReplaySubject,
	retry,
	Subscription,
	tap,
} from 'rxjs';

import { formatQueryParams } from '@app/shared/utilities/helper';

import { companyListProps, SRType, UserTypes } from '@app/shared/constants';

import { CompanyStatus } from '@app/shared/interfaces/company-status/company-status.interface';
import {
	CustomForm,
	CustomFormGeneral,
	CustomFormQuestion,
	CustomFormREWST,
	CustomFormVisibility,
} from '@interfaces/custom-form.interface';
import { CustomFormQuestionLibrary } from './pages/form-question-library/add-edit-question/question.interface';
import { CustomFormSetup } from './pages/custom-form-v2/custom-form-v2.interface';
import { IGenericInterface } from './../../shared/interfaces/generic.interface';
import { ITicketContent } from '@app/shared/components/messaging/view-ticket/ticket.interface';
import { IRequestForms } from '@app/modules/service-and-support/pages/request-forms/store/request-forms.interface';
import { ParentChildAccessType } from '@app/shared/interfaces/companies.interface';
import {
	NameId,
	SRCategory,
	SRCompanyBoard,
	SRCompanyBoardByMarket,
	SRCompanyBoards,
	SRFormByCategory,
	SRMainCategory,
	SRSmartCategory,
	SSCommonIssue,
	SSSearchResult,
} from '@interfaces/support.interface';
import { ResponseResult } from '@app/shared/interfaces/general/response/response-result.interface';
import { TableOptions } from '@app/shared/interfaces/table.interface';
import { User } from '@app/shared/interfaces/user.interface';
import { UserStatus } from '@app/shared/interfaces/user-status/user-status.interface';

import { environment } from 'environments/environment';

@Injectable({
	providedIn: 'root',
})
export class SupportService {
	static menuIds = {
		viewTickets: 63,
	};

	tableOptions: TableOptions | null;
	srType = 0;
	serviceSearchText = '';
	supportSearchText = '';
	isPublish?: boolean;

	globalRequestFormFilters: IRequestForms;
	companyRequestFormFilters: IRequestForms;
	globalSupportCategoryFilters: IGenericInterface | undefined;
	companySupportCategoryFilters: IGenericInterface | undefined;

	private _globalRequestFormData = new ReplaySubject<any[]>(1);
	globalRequestFormData$ = this._globalRequestFormData.asObservable();

	private _globalRequestFormTotalItems = new ReplaySubject<number>(1);
	globalRequestFormTotalItems$ =
		this._globalRequestFormTotalItems.asObservable();

	private _companyGlobalRequestFormData = new ReplaySubject<any[]>(1);
	companyGlobalRequestFormData$ =
		this._companyGlobalRequestFormData.asObservable();

	private _companyGlobalRequestFormTotalItems = new ReplaySubject<number>(1);
	companyGlobalRequestFormTotalItems$ =
		this._companyGlobalRequestFormTotalItems.asObservable();

	private _companyRequestFormData = new ReplaySubject<any[]>(1);
	companyRequestFormData$ = this._companyRequestFormData.asObservable();

	private _companyRequestFormTotalItems = new ReplaySubject<number>(1);
	companyRequestFormTotalItems$ =
		this._companyRequestFormTotalItems.asObservable();

	private _loadedFilterLayout = new BehaviorSubject(false);
	isFilterLayoutLoaded$ = this._loadedFilterLayout.asObservable();

	private _globalSupportCategoryData = new ReplaySubject<any[]>(1);
	globalSupportCategoryData$ = this._globalSupportCategoryData.asObservable();

	private _globalSupportCategoryTotalItems = new ReplaySubject<number>(1);
	globalSupportCategoryTotalItems$ =
		this._globalSupportCategoryTotalItems.asObservable();

	private _companySupportCategoryData = new ReplaySubject<any[]>(1);
	companySupportCategoryData$ = this._companySupportCategoryData.asObservable();

	private _companySupportCategoryTotalItems = new ReplaySubject<number>(1);
	companySupportCategoryTotalItems$ =
		this._companySupportCategoryTotalItems.asObservable();

	private _isLoading = new BehaviorSubject(false);
	isLoading$ = this._isLoading.asObservable();

	private _trackingSubs: Subscription;
	private _clientSuccessManager: User;

	constructor(private _http: HttpClient, private _router: Router) {}

	set isLoading(isLoading: boolean) {
		+this._isLoading.next(isLoading);
	}

	setFilterLayoutLoaded() {
		this._loadedFilterLayout.next(true);
	}

	clearSearch(srType: number) {
		this.srType = 0;
		if (srType === 1) {
			this.supportSearchText = '';
		} else {
			this.serviceSearchText = '';
		}
	}

	getRewst(
		questionId: number,
		value: string = '',
		companyId?: number
	): Observable<any> {
		const val = `?value=${value}`;
		const company = `${companyId ? '&companyId=' + companyId : ''}`;
		return this._http.get(
			`${environment.apiBaseUrl}SRForm/GetSRRewstData/${questionId}${val}${company}`
		);
	}
	getRewstByUrl(
		url: string,
		companyId?: number,
		rewstDependentValue?: any,
		rewstDependentKey?: any
	) {
		const urlVal = `?link=${url}`;
		const companyVal = `${companyId ? '&companyId=' + companyId : ''}`;
		const rewstVal = `${
			rewstDependentValue ? '&dependencyValue=' + rewstDependentValue : ''
		}`;
		const rewstKey = `${
			rewstDependentKey ? '&dependencyKey=' + rewstDependentKey : ''
		}`;
		return this._http.get(
			`${environment.apiBaseUrl}SRForm/GetRewstData${urlVal}${companyVal}${rewstVal}${rewstKey}`
		);
	}
	sendRewst() {
		const headerDict = {
			'x-rewst-secret': 'ouih noi7ytio87ytoi7tb87t o8',
		};
		const requestOptions = {
			headers: new HttpHeaders(headerDict),
		};
		return this._http.post(
			'https://engine.rewst.io/webhooks/custom/trigger/36ed38f7-5bdb-4baa-b55e-dfcc6e4ae3c9/63814974-6b46-4efd-99f1-3c0c82a1cafd',
			null,
			requestOptions
		);
	}

	startTracking(paths: string[]) {
		if (this._trackingSubs) this._trackingSubs.unsubscribe();
		this._trackingSubs = this._router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe((event) => {
				let unmatched = true;

				paths.forEach((path) => {
					if ((event as NavigationEnd).urlAfterRedirects.startsWith(path))
						unmatched = false;
				});

				if (unmatched) {
					this.tableOptions = null;
					this._trackingSubs.unsubscribe();
				}
			});
	}

	getRewstOptions(dataName: string) {
		return this._http.get(
			`${environment.apiBaseUrl}SRForm/GetSRRewstData/dataName?dataName=${dataName}`
		);
	}

	deleteCategory(id: number) {
		return this._http.delete(`${environment.apiBaseUrl}SRCategory/${id}`);
	}

	getCategoryDetails(id: number) {
		return this._http.get(`${environment.apiBaseUrl}SRCategory/${id}`); //userRole=${userRole}&userId=${userId}&companyId=${companyId}
	}

	addCategory(payload: Params) {
		return this._http.post(`${environment.apiBaseUrl}SRCategory`, payload);
	}

	updateCategory(payload: Params, id: number) {
		return this._http.put(`${environment.apiBaseUrl}SRCategory/${id}`, payload);
	}

	saveSupportCategoryCurrentFilters(options: IGenericInterface) {
		if (options.userRole === UserTypes.SourcepassAdmin) {
			if (options.companyId) {
				this.companySupportCategoryFilters = options;
			} else {
				this.globalSupportCategoryFilters = options;
			}
		} else if (options.userRole === UserTypes.ClientAdmin) {
			this.companySupportCategoryFilters = options;
		}
	}

	clearSupportCategoryData(options: IGenericInterface) {
		this.isLoading = true;

		if (options.userRole === UserTypes.SourcepassAdmin) {
			if (options.companyId) {
				this._companySupportCategoryData.next([]);
				this._companySupportCategoryTotalItems.next(0);
			} else {
				this._globalSupportCategoryData.next([]);
				this._globalSupportCategoryTotalItems.next(0);
			}
		} else if (options.userRole === UserTypes.ClientAdmin) {
			this._companySupportCategoryData.next([]);
			this._companySupportCategoryTotalItems.next(0);
		}
	}

	clearSupportCategoryFilter(options: IGenericInterface) {
		if (options.userRole === UserTypes.SourcepassAdmin) {
			if (options.companyId) {
				this.companySupportCategoryFilters = undefined;
			} else {
				this.globalSupportCategoryFilters = undefined;
			}
		} else if (options.userRole === UserTypes.ClientAdmin) {
			this.companySupportCategoryFilters = undefined;
		}
	}

	getSRCategory(options: IGenericInterface) {
		this.saveSupportCategoryCurrentFilters(options);
		this.clearSupportCategoryData(options);
		return this._http
			.get(
				`${environment.apiBaseUrl}SRCategory?Page=${options.page}&PageSize=${
					options.pageSize
				}&Column=${options.column}&Order=${options.order}&keyword=${
					options.query
				}${options.companyId ? '&companyId=' + options.companyId : ''}`
			)
			.pipe(
				tap((res: any) => {
					this.setSupportCategoryData(options, res);
				}),
				finalize(() => {
					this.isLoading = false;
				})
			);
	}

	private setSupportCategoryData(options: IGenericInterface, res: any) {
		if (options.userRole === UserTypes.SourcepassAdmin) {
			if (options.companyId) {
				this._companySupportCategoryData.next(res.data);
				this._companySupportCategoryTotalItems.next(res.totalCount);
			} else {
				this._globalSupportCategoryData.next(res.data);
				this._globalSupportCategoryTotalItems.next(res.totalCount);
			}
		} else if (options.userRole === UserTypes.ClientAdmin) {
			this._companySupportCategoryData.next(res.data);
			this._companySupportCategoryTotalItems.next(res.totalCount);
		}
	}

	getFormSubmission(
		page: number = 1,
		pageSize: number = 10,
		column: string = 'name',
		order: string = 'ASC',
		keyword: string = '',
		formId: number,
		queryUser: any,
		queryCompany: any
	) {
		return this._http.get<{
			data: SRCategory[];
			totalCount: number;
			currentPage: number;
		}>(
			`${environment.apiBaseUrl}SRForm/GetFormSubmissionByFormId/${formId}?Page=${page}&PageSize=${pageSize}&Column=${column}&Order=${order}&Search=${keyword}&UserId=${queryUser}&CompanyId=${queryCompany}`
		);
	}

	categories(type?: SRType) {
		return this._http.get<{ data: SRCategory[] }>(
			`${environment.apiBaseUrl}SRCategory/GetByUserPermission`
		);
	}

	categoriesDropdown(type?: SRType) {
		return this._http
			.get<{ data: (NameId & { supportCategoryIds: number[] })[] }>(
				`${environment.apiBaseUrl}SRCategory/GetCategoryDropdownList${
					type ? '?categoryType=' + type : ''
				}`
			)
			.pipe(retry(1));
	}

	companiesDropdown() {
		return this._http.get<NameId[]>(
			`${environment.apiBaseUrl}companies/getdropdown`
		);
	}

	vertivalMarketsDropdown() {
		return this._http.get<NameId[]>(
			`${environment.apiBaseUrl}markets/getdropdown`
		);
	}

	formAlternative() {
		return this._http.get<NameId[]>(
			`${environment.apiBaseUrl}SRForm/GetFormAlternative`
		);
	}

	boardsDropdown() {
		return this._http.get<NameId[]>(
			`${environment.apiBaseUrl}ticketboards/getdropdown`
		);
	}

	updateFormAssignment(boardId: number, isFormAssign: boolean) {
		const url = `${environment.apiBaseUrl}SRBoard/UpdateFormAssignment/${boardId}`;

		return this._http.put<{ data: NameId[] }>(url, {
			boardId,
			isFormAssign,
		});
	}

	boardsGlobalDropdown() {
		return this._http.get<{ data: NameId[] }>(
			`${environment.apiBaseUrl}SRBoard/GetGlobalBoardDropdown`
		);
	}

	boardsByMarketDropdown(id: number) {
		return this._http.get<{ data: NameId[] }>(
			`${environment.apiBaseUrl}SRBoard/GetBoardDropdownListByMarketId?MarketId=${id}`
		);
	}

	boardsByCompanyDropdown(id: number | string) {
		return this._http.get<{ data: NameId[] }>(
			`${environment.apiBaseUrl}SRBoard/GetBoardDropdownListByCompanyIds?CompanyIds=${id}`
		);
	}

	companyBoardsByVerticalMarket(ids: number[]) {
		return this._http.post<{ data: SRCompanyBoardByMarket[] }>(
			`${environment.apiBaseUrl}SRBoard/GetCompanyDefaultBoardandDropdownByVerticalMarketId`,
			ids
		);
	}

	companyBoards(
		ids: number[],
		selectedCustomCompanyId: number
	): Observable<{ data: SRCompanyBoards[] }> {
		return this._http
			.post<any>(
				`${environment.apiBaseUrl}SRBoard/GetCompanyCommonBoardDropdown`,
				ids
			)
			.pipe(
				map((response) => {
					return {
						data: [
							{ boards: response.data, companyId: selectedCustomCompanyId },
						],
					};
				})
			);
	}

	companyCommonBoards(ids: number[]) {
		return this._http.post<{ data: NameId[] }>(
			`${environment.apiBaseUrl}SRBoard/GetCompanyCommonBoardDropdown?`,
			ids
		);
	}

	companiesBoards(ids: number[]) {
		return this._http.post<{ data: SRCompanyBoard[] }>(
			`${environment.apiBaseUrl}SRBoard/GetCompanyDefaultBoardandDropdownByCompanyIds`,
			ids
		);
	}

	companyUserCountDropdown(formId: number, options: companyListProps) {
		return this._http.get<{ data: any[]; totalCount: number }>(
			`${
				environment.apiBaseUrl
			}SRForm/GetCompanyPermissionCountByFormId?FormId=${formId}&Page=${
				options.page
			}&PageSize=${options.pageSize}${
				options.order ? '&Order=' + options.order : ''
			}${options.column ? '&Column=' + options.column : ''}${
				options.search ? '&Search=' + options.search : ''
			}`
		);
	}

	formListByCategory(categoryId: number) {
		return this._http.get<{ data: SRFormByCategory[] }>(
			`${environment.apiBaseUrl}SRForm/GetFormListByCategoryId/${categoryId}`
		);
	}

	formListByPermission(categoryId: number, categoryTypeId: SRType) {
		return this._http.get<{ data: SRFormByCategory[] }>(
			`${environment.apiBaseUrl}SRForm/GetFormByUserPermission/${categoryId}`
		);
	}
	getPublishDropdown() {
		return of([
			{
				val: undefined,
				label: 'All',
			},
			{
				val: true,
				label: 'Yes',
			},
			{
				val: false,
				label: 'No',
			},
		]);
	}
	//Custom Form
	customFormList(options: IRequestForms) {
		this.saveRequestFormCurrentFilter(options);
		this.clearRequestFormData(options);

		return this._http
			.get<{ data: NameId[]; totalCount: number }>(
				`${environment.apiBaseUrl}SRForm?Page=${options.page}&PageSize=${
					options.pageSize
				}${options.order ? '&Order=' + options.order : ''}${
					options.column ? '&Column=' + options.column : ''
				}${options.query ? '&searchText=' + options.query : ''}${
					typeof options.categoryId == 'object'
						? options.categoryId.map((c) => '&categoryId=' + c).join('')
						: options.categoryId
						? '&categoryId=' + options.categoryId
						: ''
				}${options.dateStart ? '&dateStart=' + options.dateStart : ''}${
					options.dateEnd ? '&dateEnd=' + options.dateEnd : ''
				}${
					typeof options.isPublish === 'boolean'
						? '&isPublish=' + options.isPublish
						: ''
				}${options.companyId ? '&companyId=' + options.companyId : ''}
				&isRewst=${options.isRewst ?? ''}`
			)
			.pipe(
				tap((res) => {
					this.setRequestFormData(options, res);
				}),
				finalize(() => {
					this.isLoading = false;
				})
			);
	}

	saveRequestFormCurrentFilter(options: IRequestForms) {
		if (options.userRole === UserTypes.SourcepassAdmin) {
			if (options.companyId2) {
				this.companyRequestFormFilters = options;
			} else {
				this.globalRequestFormFilters = options;
			}
		} else if (options.userRole === UserTypes.ClientAdmin) {
			this.companyRequestFormFilters = options;
		}
	}

	clearRequestFormData(options: IRequestForms) {
		this.isLoading = true;

		if (options.userRole === UserTypes.SourcepassAdmin) {
			if (options.companyId2) {
				this._companyRequestFormData.next([]);
				this._companyRequestFormTotalItems.next(0);
			} else {
				this._globalRequestFormData.next([]);
				this._globalRequestFormTotalItems.next(0);
			}
		} else if (options.userRole === UserTypes.ClientAdmin) {
			this._companyRequestFormData.next([]);
			this._companyRequestFormTotalItems.next(0);
		}
	}

	setRequestFormData(options: IRequestForms, res: any) {
		if (options.userRole === UserTypes.SourcepassAdmin) {
			if (options.companyId2) {
				this._companyRequestFormData.next(res.data);
				this._companyRequestFormTotalItems.next(res.totalCount);
			} else {
				this._globalRequestFormData.next(res.data);
				this._globalRequestFormTotalItems.next(res.totalCount);
			}
		} else if (options.userRole === UserTypes.ClientAdmin) {
			this._companyRequestFormData.next(res.data);
			this._companyRequestFormTotalItems.next(res.totalCount);
		}
	}

	customFormById(id: number) {
		return this._http
			.get<CustomForm>(`${environment.apiBaseUrl}SRForm/` + id)
			.pipe(retry(1));
	}

	saveSRFormGeneralSettings(data: CustomFormGeneral) {
		return this._http.post<CustomFormGeneral>(
			`${environment.apiBaseUrl}SRForm/SaveGeneralSetting`,
			data
		);
	}

	submitToRewst(formBody: any) {
		return this._http.post(
			`${environment.apiBaseUrl}UserTicketResponse/SubmitToRewst`,
			formBody
		);
	}

	saveSRFormVisibility(formId: number, visibility: CustomFormVisibility) {
		const body = { formId, ...visibility };
		return this._http.post<typeof body>(
			`${environment.apiBaseUrl}SRForm/SaveVisibilitySetting`,
			body
		);
	}

	saveSRFormREWST(formId: number, rewst: CustomFormREWST) {
		const body = { formId, ...rewst };
		return this._http.post<typeof body>(
			`${environment.apiBaseUrl}SRForm/SaveRewstSetting`,
			body
		);
	}

	saveSRFormQuestions(formId: number, questions: CustomFormQuestion[]) {
		const body = { formId, questions };
		return this._http.post<typeof body>(
			`${environment.apiBaseUrl}SRForm/SaveQuestionSetting`,
			body
		);
	}

	saveSRFormCustomQuestions(
		formId: number,
		questions: CustomFormQuestion[],
		customOrder: any[]
	) {
		const body = { formId, questions, customOrder };
		return this._http.post<typeof body>(
			`${environment.apiBaseUrl}SRForm/SaveCustomQuestionSetting`,
			body
		);
	}

	publishSRForm(formId: number, isPublish: boolean) {
		const body = { formId, isPublish };
		return this._http.post<CustomForm>(
			`${environment.apiBaseUrl}SRForm/SavePublishedSetting`,
			body
		);
	}

	duplicateCustomForm(formId: number | null, title: string) {
		return this._http.post(`${environment.apiBaseUrl}SRForm/AddFormDuplicate`, {
			formId,
			title,
		});
	}

	deleteCustomForm(formId: number | null) {
		return this._http.delete(`${environment.apiBaseUrl}SRForm/${formId}`);
	}
	//Custom Form End

	toFlatQuestions(questions: CustomFormQuestion[], withIdOnly?: boolean) {
		let qs: CustomFormQuestion[] = [];
		questions.forEach((q) => {
			if (!withIdOnly || q.id) {
				let subQs = this.toFlatQuestions(q.subQuestions!);
				delete q.subQuestions;
				qs.push(q);
				subQs.forEach((sq) => {
					if (!sq.parentQuestionId) sq.parentQuestionId = q.id || q.tempId!;
				});
				qs = qs.concat(subQs);
			}
		});
		return qs;
	}

	getSRCategoryHistory(
		srCategoryId: number,
		pageNumber: number,
		pageSize: number
	) {
		return this._http.get<History[]>(
			`${environment.apiBaseUrl}srcategory/history/${srCategoryId}?page=${pageNumber}&pageSize=${pageSize}`
		);
	}
	getSRFormHistory(srFormId: number, pageNumber: number, pageSize: number) {
		return this._http.get<History[]>(
			`${environment.apiBaseUrl}srform/history/${srFormId}?page=${pageNumber}&pageSize=${pageSize}`
		);
	}
	getSRFormByUserPermission(options: any) {
		return this._http.get<any>(
			`${environment.apiBaseUrl}SRForm/SRFormUserPermission`,
			{ params: options }
		);
	}
	setContactLevelFormAccess(data: any) {
		return this._http.post<any>(
			`${environment.apiBaseUrl}SRForm/UpdateUserAccess`,
			data
		);
	}

	getUser(formId: number) {
		let url = `${environment.apiBaseUrl}SRForm/GetUserDropdownList/${formId}`;
		return this._http.get<UserStatus[]>(url);
	}

	getCompany(formId: number) {
		let url = `${environment.apiBaseUrl}SRForm/GetCompanyDropdownList/${formId}`;
		return this._http.get<CompanyStatus[]>(url);
	}

	getQuestionLibrary(page: any) {
		let url = `${environment.apiBaseUrl}QuestionLibrary`;
		return this._http.get(url, { params: page });
	}
	getQuestionLibraryById(id: number) {
		let url = `${environment.apiBaseUrl}QuestionLibrary/${id}`;
		return this._http.get(url);
	}
	deleteQuestionLibrary(id: number) {
		return this._http.delete(`${environment.apiBaseUrl}QuestionLibrary/${id}`);
	}
	saveQuestionLibrary(body: CustomFormQuestionLibrary, id?: number) {
		if (id) {
			return this._http.put(
				`${environment.apiBaseUrl}QuestionLibrary/${id}`,
				body
			);
		} else {
			return this._http.post(`${environment.apiBaseUrl}QuestionLibrary`, body);
		}
	}
	questionLibraryDropdown() {
		return this._http.get(
			`${environment.apiBaseUrl}QuestionLibrary/GetDropdown`
		);
	}

	//Service and support v2
	async getClientSuccessManager(companyId: number): Promise<User | undefined> {
		return new Promise((resolve) => {
			if (
				!this._clientSuccessManager ||
				this._clientSuccessManager.companyId != companyId
			) {
				const url = `${environment.apiBaseUrl}ServiceAndSupport-v2/GetClientSuccessManager`;
				this._http.get<User>(url).subscribe({
					next: (res) => {
						this._clientSuccessManager = res;
						resolve(this._clientSuccessManager);
					},
					error: (err) => {
						resolve(undefined);
					},
				});
			} else resolve(this._clientSuccessManager);
		});
	}

	getMainCategories() {
		const url = `${environment.apiBaseUrl}ServiceAndSupport-v2/GetCategoryList`;
		return this._http.get<SRMainCategory[]>(url);
	}

	getSubCategories(categoryId: number) {
		const url = `${environment.apiBaseUrl}ServiceAndSupport-v2/GetCategoryList?id=${categoryId}`;
		return this._http.get<SRMainCategory[]>(url);
	}

	getSubCategory(categoryId: number) {
		const url = `${environment.apiBaseUrl}ServiceAndSupport-v2/GetSubCategoryInfo?id=${categoryId}`;
		return this._http.get<SRMainCategory[]>(url);
	}

	getRequestForms(categoryId: number) {
		const url = `${environment.apiBaseUrl}ServiceAndSupport-v2/GetRequestForms?srCategoryId=${categoryId}`;
		return this._http.get<SRFormByCategory[]>(url);
	}

	getSmartCategories(categoryId: number) {
		const url = `${environment.apiBaseUrl}ServiceAndSupport-v2/GetSmartCategories?id=${categoryId}`;
		return this._http.get<SRSmartCategory[]>(url);
	}

	getSmartForms(categoryId: number, size: number) {
		const url = `${environment.apiBaseUrl}ServiceAndSupport-v2/GetSmartForms?id=${categoryId}&size=${size}`;
		return this._http.get<{forms: SRFormByCategory[], viewMore: boolean}>(url);
	}

	search(
		keyword: string
	): Observable<{ ticket: SSSearchResult[]; kb: SSSearchResult[] }> {
		const url = `${
			environment.apiBaseUrl
		}ServiceAndSupport-v2/GetSearchResult?PageSize=${
			keyword ? 50 : 6
		}&keyword=${keyword}`;
		return this._http.get<ResponseResult<SSSearchResult[]>>(url).pipe(
			map((res) => {
				const result: { ticket: SSSearchResult[]; kb: SSSearchResult[] } = {
					ticket: [],
					kb: [],
				};
				res.data.forEach((r) => {
					if (r.isKbTopic) {
						r.description = r.description.split(', "css":')[0];
						r.description = r.description
							.replace(/(<([^>]+)>)/gi, '')
							.replace(/(\r\n|\n|\\n|\r)/gm, ' ');
						r.description = r.description.substring(
							r.description.indexOf(' ') + 1
						);
						r.description =
							r.description.substring(0, r.description.lastIndexOf(' ')) +
							'...';
						result.kb.push(r);
					} else result.ticket.push(r);
				});
				return result;
			})
		);
	}

	getCommonIssues(categoryId?: number, pageSize?: number) {
		const url = `${
			environment.apiBaseUrl
		}ServiceAndSupport-v2/GetMostCommonIssues?mainCategoryId=${
			categoryId ?? 0
		}`;
		return this._http.get<ResponseResult<SSCommonIssue[]>>(url);
	}

	getKnowledgeBaseTopics(categoryId?: number, pageSize?: number) {
		const url = `${
			environment.apiBaseUrl
		}ServiceAndSupport-v2/GetKnowledgeBaseTopics?srCategoryId=${
			categoryId ?? 0
		}`;
		return this._http.get<ResponseResult<SSCommonIssue[]>>(url);
	}

	getRecurringIssue() {
		const url = `${environment.apiBaseUrl}ServiceAndSupport-v2/GetRecurringIssues`;
		return this._http.get<ResponseResult<ITicketContent[]>>(url);
	}

	getTicketChildCompanies() {
		const url = `${environment.apiBaseUrl}ParentChildCompany/GetCurrentChildren?access=${ParentChildAccessType.Ticket}`;
		return this._http.get<NameId[]>(url);
	}

	// CUSTOM FORM V2 API
	customFormByIdUserV2(id: number) {
		return this._http
			.get<CustomForm>(
				`${environment.apiBaseUrl}SRForm-v2/getUserFormDetailsById/` + id
			)
			.pipe(retry(1));
	}

	usageFormV2(usageId: number) {
		return this._http.get<CustomForm>(
			`${environment.apiBaseUrl}SRForm-v2/GetFormUsageDetailsById/` + usageId
		);
	}

	customFormByIdV2(id: number, companyId?: number) {
		const company = `?companyId=${companyId ? companyId : ''}`;
		return this._http
			.get<any>(`${environment.apiBaseUrl}SRForm-v2/${id}${company}`)
			.pipe(retry(1));
	}

	saveSRFormGeneralSettingsV2(data: CustomFormSetup) {
		const headers = new HttpHeaders().set('x-api-version', '1.1');

		return this._http.post<CustomFormSetup>(
			`${environment.apiBaseUrl}SRForm-v2/SaveGeneralSetting`,
			data,
			{ headers }
		);
	}

	saveSRFormQuestionsV2(formId: number, section: any[], condition: any[]) {
		const body = { formId, section, condition };
		return this._http.post<typeof body>(
			`${environment.apiBaseUrl}SRForm-v2/SaveQuestionSetting`,
			body
		);
	}

	saveSRFormDeliverySettingsV2(settings: any) {
		const headers = new HttpHeaders().set('x-api-version', '1.1');

		return this._http.post(
			`${environment.apiBaseUrl}SRForm-v2/SaveDeliverySetting`,
			settings, 
			{ headers }
		);
	}

	getQuestionLibraries() {
		const url = `${environment.apiBaseUrl}QuestionLibrary/GetDropdown`;
		return this._http.get<any[]>(url);
	}
	saveQuestionLibraryV2(body: any, id?: number) {
		if (id) {
			return this._http.put(
				`${environment.apiBaseUrl}SRForm-v2/SaveQuestionLibrarySetting/${id}`,
				body
			);
		} else {
			return this._http.post(
				`${environment.apiBaseUrl}SRForm-v2/SaveQuestionLibrarySetting`,
				body
			);
		}
	}
	getQuestionLibraryByIdV2(id: number) {
		const url = `${environment.apiBaseUrl}SRForm-v2/GetQuestionLibraryById/${id}`;
		return this._http.get(url);
	}
	saveAccessSettingsV2(access: any, formId: number) {
		const payload = { access, formId };
		return this._http.post(
			`${environment.apiBaseUrl}SRForm-v2/SaveAccessSetting`,
			payload
		);
	}

	getFormChildCompanies(companyId: number) {
		const url = `${environment.apiBaseUrl}ParentChildCompany/GetCurrentChildren/${companyId}?access=ticket`;
		return this._http.get<NameId[]>(url);
	}

	getFormCompanies(
		options: { formId: number; isGetAvailable: boolean } & TableOptions
	) {
		const url =
			`${environment.apiBaseUrl}SRForm-v2/GetCompanyAccessSetting` +
			formatQueryParams(options);
		return this._http.get<ResponseResult<NameId[]>>(url);
	}

	getFormRoles(
		options: { formId: number; isGetAvailable: boolean } & TableOptions
	) {
		const url =
			`${environment.apiBaseUrl}SRForm-v2/GetRoleAccessSetting` +
			formatQueryParams(options);
		return this._http.get<ResponseResult<NameId[]>>(url);
	}

	getRoleUsers(options: TableOptions) {
		const url = `${environment.apiBaseUrl}SRForm-v2/GetUserByCompanies`;
		return this._http.post<ResponseResult<any[]>>(url, options);
	}

	getSystemInformation(formId: number) {
		const body = { formId };
		const url = `${environment.apiBaseUrl}UserTicketResponse/AddFormDesktopDetail`;
		return this._http.post<any>(url, body);
	}
}
