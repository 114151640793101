import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { environment } from 'environments/environment';
import { catchError, of } from 'rxjs';

@Component({
	selector: 'quest-icon',
	templateUrl: './quest-icon.component.html',
	styleUrls: ['./quest-icon.component.scss'],
})
export class QuestIconComponent implements OnInit {
	@Input() name!: string;
	isLoaded = false;

	constructor(
		private _matIconRegistry: MatIconRegistry,
		private _domSanitizer: DomSanitizer
	) {}

	ngOnInit() {
		if (!this._matIconRegistry['_svgIconConfigs'].has(`:${this.name}`)) {
			this._matIconRegistry.addSvgIcon(
				this.name,
				this._domSanitizer.bypassSecurityTrustResourceUrl(
					`${environment.iconBaseUrl}${this.name}.svg`
				)
			);
		}

		this._matIconRegistry
			.getNamedSvgIcon(this.name)
			.pipe(catchError((e) => of(false)))
			.subscribe((i) => {
				if (i) this.isLoaded = true;
			});
	}
}
