interface MenuAction {
	id: number;
	name: string;
	isEnableAccess: boolean;
	defaultRoleAccess: boolean;
	isPush: boolean;
	menuName: string;
}

export interface Menu {
	menuActions: MenuAction[];
	icon: string;
	menuGroupName: string;
}

export interface nextMenu {
	menuId: number;
	menuName: string;
	roleId: string;
	isEnableAccess: boolean;
	userId: number;
	menuGroupId: number;
}

export interface GroupedMenuAction {
	menuGroupName: string;
	icon: string;
	menuActions: MenuAction[];
}

export interface RoleList {
	id: number;
	title: string;
	count: number;
	addUser: boolean;
	editRole: boolean;
	deleteRole: boolean;
	description: string;
}

export interface RoleList2 {
	roleId: number;
	roleName: string;
	roleDescription: string;
	isAdmin: boolean;
	isBaseRole: boolean;
	userCount: number;
	isFinal?: boolean;
	isEditable: boolean;
	isDeletable: boolean;
	isOverride: boolean;
}

/**ModulesPermission**/
export interface ModulesPermission {
	id: number;
	name: string;
	icon: string;
	isAdmin: boolean;
	isAllAccess: boolean;
	pages?: ModulePages[];
}

export interface ModulesPermission2 {
	id: number;
	menuGroupId: number;
	name: string;
	isAllAccess: boolean;
	icon?: string;
	description: string;
	figmaIconId: string;
	iconUrl: string;
}

export interface ModulePages {
	id: number;
	name: string;
	description: string;
	isEnableAccess: boolean;
	actions: PageAction[];
}

export interface ModulePages2 {
	id: number;
	name: string;
	userId: number;
	description: string;
	countsLabel: string;
	figmaIconId: string;
	defaultRoleAccess: boolean;
	icon: string;
	color: string;
	menuId: number;
	roleId: number;
	url: string;
	menuGroupId: number;
	menuActions: PageAction2[];
	isCustomRoute: boolean;
	isEnableAccess: boolean;
	isNavigatable: boolean;
	companyId?: number;
	buttonLabel?: string;
}

export interface OverrideModulePages {
	menuAccessList: Partial<ModulePages2>[];
	roleId: number;
	userId?: number;
	companyId?: number;
	isOverride: boolean;
}

export interface PageAction2 {
	actionId: number;
	id: number;
	companyId: number;
	userId: number;
	isActive: boolean;
	isEnableAccess: boolean;
	defaultRoleAccess: boolean;
	isPush: boolean;
	isView: boolean;
	menuId: number;
	roleId: number;
	name: string;
}

export interface PageAction {
	id: number;
	isEnable: boolean;
	name: string;
	displayName: string;
}

export interface RoleHeader {
	id: number;
	baseRoleId: number;
	companyId: number;
	name: string;
	description: string;
	isAdmin: boolean;
	isFinal: boolean;
	userCount: number;
	isDefault: number;
	enableDeleteProtection: boolean;
	hasOverrideUsers: boolean;
}

export interface UserRoleForms {
	id: number;
	categories: string;
	title: string;
	isAccess: boolean;
}

export interface GenericTable {
	currentPage: number;
	data: any[] | UserRoleForms[] | UserRoleForms2[];
	pageSize: number;
	totalCount: number;
}

export interface UserRoleForms2 extends UserRoleForms {
	isCheck?: boolean;
}

export interface RoleUser {
	userId: number;
	firstName: string;
	lastName: string;
	title: string;
	imagePath: string;
	companyId: number;
	companyName: string;
	isBillingContact: boolean;
}

// short hand
export type ModuleList = Omit<ModulesPermission, 'pages'>;
/***/
